/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import useUser from 'hooks/useUser';
import {Modal} from 'flowbite-react';
import {useEffect, useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './stockModal.scss';
import {locationSelector, orgSelector} from 'redux/selectors';
import {useSelector} from 'react-redux';
import MultiSelect from 'components/select/index';
import {sortByTypeType} from 'utils/constants';
import {getPurchaseOrderById, getPurchaseOrdersByFilter} from 'api/purchaseOrderApi';
import axios from 'axios';
import urls from 'api/urls';
import {toast} from 'react-toastify';
import FileUpload from 'components/commonComponent/fileUpload/dragDropFileUpload';
import ReceivedShipmentModal from './receivedShipmentModal';

type importShipmentModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    targetData?: any,
    isEdit: boolean,
    isImportShipment: boolean,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const ImportShipmentModal = (props: importShipmentModalProps) => {
    const {
        openModal,
        targetData,
        isEdit,
        isImportShipment,
        setOpenModal,
        headerTitle,
        currentPage,
        queryClient,
        setCurrentPage
    } = props;
    const [openModalManually, setOpenModalManually] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [data, setData] = useState([]);
    const [file, setFile] = useState(null);
    const [fileContent, setFileContent] = useState('');
    const [purchaseOrder, setPurchaseOrder] = useState(null);
    const [purchaseOrderSelected, setPurchaseOrderSelected] = useState(null);
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([]);
    const [purchaseOrdersOption, setPurchaseOrdersOption] = useState([{label: '...', value: ''}]);
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const rootRef = useRef(null);
    const [error, setErrors] = useState(false);
    const {organizationId} = useSelector(orgSelector);
    const {locationId} = useSelector(locationSelector);

    useEffect(() => {

        getPurchaseOrdersByFilter({
            page: 0,
            limit: 0,
            sortByType: sortByTypeType.DESC,
            locationId,
            statuss: 'Partially Received,Ordered'
        }).then(res => {
            const newOption = res?.data?.entities?.map((i: any) => ({
                label: `Order No: #${i.orderN2GId}`,
                value: i.id
            }));
            setPurchaseOrdersOption(newOption);
        });

        // eslint-disable-next-line
    }, []);

    const toggleHandler = () => {
        setOpenModal(!openModal);
    };

    const toggleContinue = () => {
        if (!file) {
            toast.error(`${t('uploadFile.noUpload')}`);
        } else {
            if (purchaseOrderSelected === null) {
                toast.error(`${t('orderPage.noChooseOrder')}`);
            } else {
                const nextLineRegex = /\r\n/;
                const lineFound = fileContent.match(nextLineRegex);
                if (lineFound) {
                    const dataLine = fileContent.split(nextLineRegex);
                    const dataLineFilter = dataLine.filter((x: any) => x !== "");
                    const dataByFile = buildDataByFile(dataLineFilter);
                    setData(dataByFile);
                    setIsImport(!isImport);
                    setOpenModalManually(!openModalManually);
                } else {
                    setIsImport(!isImport);
                    setOpenModalManually(!openModalManually);
                }
            }
        }
    };

    const buildDataByFile = (fileContent: any) => {
        const dataByContent: any = [];
        for (let index = 0; index < fileContent.length; index++) {
            const element = fileContent[index];
            const tabRegex = /\t/;
            const tabFound = element.match(tabRegex);
            if (tabFound) {
                const dataTab = element.split(tabRegex);
                const temp = {
                    upc: dataTab[0],
                    quantity: parseInt(dataTab[1]),
                    action: dataTab[2]
                }
                dataByContent.push(temp);
            }
        }
        return dataByContent;
    }

    const handleChangePurchaseOrder = async (option: any) => {
        setPurchaseOrderSelected(option);
        const res = await getPurchaseOrderById(option?.value);
        if (res?.data) {
            const poItems = res?.data?.purchaseOrderItems;
            setPurchaseOrder(res?.data);
            let poOderItems : any = [];
            axios.get(`${urls.purchaseOrder.receivingOrderN2G}/?orderId=${res?.data?.orderN2GId}`).then(response => {
                if (response.data?.shippedItems?.length > 0) {
                    for (let index = 0; index < response.data?.shippedItems?.length; index++) {
                        const element = response.data?.shippedItems?.[index];
                        const item =  poItems?.find((x: any) => x.item?.sku === element.sku);
                        if (item) {
                            if (item.quantityReceived === 0) {
                                item.quantityReceived = element.quantity;
                            }
                        }
                        poOderItems.push(item);
                    }
                }
                else {
                    poOderItems = poItems;
                }
                setPurchaseOrderItems(poOderItems);
            })
            .catch(error => {
                  setPurchaseOrderItems(poItems);
                  toast.error(`${t('orderPage.errorN2gApi')}`);
            });
        }
    };

    return (
        <>
            <div ref={rootRef} className="import-shipment-modal h-modal">
                <Modal
                    show={openModal}
                    size="5xl"
                    className="stock-modal"
                    root={rootRef.current ?? undefined}
                    onClose={toggleHandler}
                    dismissible={false}
                    theme={themModal}
                >
                    <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                    <Modal.Body theme={{base: 'flex flex-col items-center'}}>
                        <div className="mt-5 text-sm font-normal">{t('stockPage.modal.headerImport')}</div>
                        <div className='mt-5 mb-7 w-[300px]'>
                            <MultiSelect
                                    onChange={handleChangePurchaseOrder}
                                    options={purchaseOrdersOption}
                                    value={purchaseOrderSelected}
                                    name="purchaseOderId"
                                    noOptionsMessage={() => t('No options')}
                                    closeMenuOnSelect
                                    isHolderLabel={true}
                                    placeholder="Purchase Order"
                            />
                        </div>
                        <div className="mb-3 text-sm font-normal">{t('stockPage.modal.headerChooseFile')}</div>
                        <FileUpload file={file} setFile={setFile} fileContent={fileContent} setFileContent={setFileContent} fileTypes={['txt']} headerUpload={''}/>
                        <div className="w-full flex justify-end fixed-group-buttons">
                            <GroupButton
                                className=""
                                buttons={[
                                    {
                                        type: 'button',
                                        text: t('modal.continue'),
                                        classType: 'blue',
                                        action: () => toggleContinue(),
                                    },
                                ]}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {openModalManually && (
                <ReceivedShipmentModal
                    openParentModal={openModal}
                    setOpenParentModal={setOpenModal}
                    headerTitle={headerTitle}
                    openModal={openModalManually}
                    isEdit={isImport}
                    isImportShipment={isImportShipment}
                    targetData={data}
                    purchaseOrder={purchaseOrder}
                    purchaseOrderItems={purchaseOrderItems}
                    setOpenModal={setOpenModalManually}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
        </>
    );
};
export default ImportShipmentModal;
