/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import useUser from 'hooks/useUser';
import {Modal} from 'flowbite-react';
import {useEffect, useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './purchaseOrderModal.scss';
import {locationSelector, orgSelector} from 'redux/selectors';
import {useSelector} from 'react-redux';
import {getCategoriesByFilter} from 'api/categoryApi';
import {sortByTypeType, defaultpageCount} from 'utils/constants';
import {useForm} from 'react-hook-form';
import MultiSelect from 'components/select/index'
import GroupButton from 'components/button/groupButton';

type createItemByCategoryModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    category?: any,
    setCategory: Function,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const CreateItemByCategoryModal = (props: createItemByCategoryModalProps) => {
    const {
        openModal,
        category,
        setCategory,
        setOpenModal,
        headerTitle,
        currentPage,
        queryClient,
        setCurrentPage
    } = props;
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const rootRef = useRef(null);
    const [error, setErrors] = useState(false);
    const {organizationId} = useSelector(orgSelector);
    const {locationId} = useSelector(locationSelector);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [categoryIds, setCategoryIds] = useState([]);
    const [categoryTypeOptions, setCategoryTypeOptions] = useState([{label: '...', value: ''}]);

    const hookForm = useForm({
        mode: "onChange",
    });

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        reset,
        getValues,
    } = hookForm;

    useEffect(() => {
        getCategoriesByFilter({ page: 0, limit: 5, sortByType: sortByTypeType.DESC, organizationId })?.then(res => {
            const totalCount = res?.data?.totalEntities;
            if (totalCount === 0) {
              setCategoryTypeOptions([]);
            } else {
              const pageBreak = Math.ceil(totalCount / defaultpageCount);
              let listTemps: any = [];
              for (let index = 0; index < pageBreak; index++) {
                // eslint-disable-next-line
                getCategoriesByFilter({ page: index, limit: defaultpageCount, sortByType: sortByTypeType.DESC, organizationId })?.then(resPage => {
                  if (listTemps.length === 0) {
                    listTemps = resPage?.data?.entities;
                  } else {
                    listTemps = listTemps.concat(resPage?.data?.entities);
                  }
      
                  if (listTemps.length > 0) {
                    const newOption = listTemps.map((i: any) => ({ label: i.name, value: i.id }));
                    setCategoryTypeOptions(newOption);
                  }
                });
              }
            }
          });

        // eslint-disable-next-line
    }, []);

    const toggleHandler = () => {
        setOpenModal(!openModal);
    };

    const handleChangeCategory = (options: any) => {
        setSelectedCategory(options);
        setCategoryIds(options.map((i: any) => {
            return i.value
        }));
    };

    const handleOk = () => {
        setCategory(categoryIds);
        toggleHandler();
    }

    return (
        <div ref={rootRef} className="order-modal-container">
            <Modal
                show={openModal}
                size="xl"
                className="order-modal"
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={false}
                theme={themModal}
            >
                <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                <Modal.Body theme={{base: 'flex-12 pb-4 pt-3 px-12'}}>
                    <form>
                        <div className="mt-4 relative">
                            <MultiSelect
                                onChange={(option: any) => handleChangeCategory(option)}
                                options={categoryTypeOptions}
                                value={selectedCategory}
                                isMulti={true}
                                hookForm={hookForm}
                                name="categoryId"
                                noOptionsMessage={() => t('No options')}
                                closeMenuOnSelect
                                isHolderLabel={true}
                                placeholder={`${t('orderPage.category')}`}
                            />
                        </div>
                        <GroupButton
                            className="items-center justify-center pt-5 mb-4"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.cancel'),
                                    classType: 'white',
                                    action: () => toggleHandler(),
                                },
                                {
                                    type: 'button',
                                    text: t('modal.ok'),
                                    classType: 'blue',
                                    isLoading: false,
                                    action: () => handleOk(),
                                },
                            ]}
                        />
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default CreateItemByCategoryModal;
