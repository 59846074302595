import {stockTypes} from 'utils/proptypes';
import PaginateTable from 'components/table/paginate';
import {HiOutlineDuplicate} from 'react-icons/hi';
import {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {initStock} from 'utils/initData';
import SpinnerComponent from 'components/spinner';
import {defaultpageCount, permissionKeys, routePaths, sortByTypeType} from 'utils/constants';
import {isEqual} from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import {toast} from 'react-toastify';
import {checkPermission, messageErrors, copyToClipboard, formatDateNotTime, formatMoney, checkUseRegionAndLocation} from '../../utils/utils';
import {useSelector} from 'react-redux';
import ActionTable from 'components/table/actionTable';
import {locationSelector, orgSelector, userSelector} from 'redux/selectors';
import useUser from 'hooks/useUser';
import '../../styles/styles.scss';
import {Table, Tooltip} from 'flowbite-react';
import Sort from 'components/table/sort';
import EmptyState from 'components/commonComponent/emptyState';
import { exportStockFormOrg, exportStockLedgerFormOrg, getStocksByFilter } from 'api/stockApi';
import ReconcileStockModal from 'components/modal/stockModal/reconcileStockModal';
import ImportShipmentModal from 'components/modal/stockModal/importShipmentModal';
import ExportStockModal from 'components/modal/stockModal/exportStockModal';
import ReconcileReviewModal from 'components/modal/stockModal/reconcileReviewModal';
import ExportStockLedgerModal from 'components/modal/stockModal/exportStockLedgerModal';

const stockEmpty = require('../../assets/image/svg/Emptys_Stock.svg').default || '';
const locationEmty = require('../../assets/image/svg/location-marker.svg').default || '';

const StockList = (props: any) => {
    const queryClient = useQueryClient();
    const {WRITE_STOCK, WRITE_PURCHASE_ORDER, VIEW_REPORT, PLATFORM_ADMIN} = permissionKeys;
    const [t] = useTranslation();
    const {handleSetTookie} = useUser();
    const {userInfo} = useSelector(userSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [openReconcileModal, setOpenReconcileModal] = useState(false);
    const [isImportShipment, setIsImportShipment] = useState(false);
    const [openModalExport, setOpenModalExport] = useState(false);
    const [openModalExportLedger, setOpenModalExportLedger] = useState(false);
    const [openReconcileReviewModal, setOpenReconcileReviewModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [targetData, setTargetData] = useState([]);
    const [stocks, setStocks] = useState([initStock]);
    const [allStocks, setAllStocks] = useState([]);
    const [targetStock, setTargetStock] = useState(initStock);
    const [sortBy, setSortBy] = useState('');
    const [sortByType, setSortByType] = useState('');
    const navigate = useNavigate();
    const [pageCount, setPageCount] = useState(defaultpageCount);
    const { organizationId } = useSelector(orgSelector);
    const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_STOCK], organizationId);
    const isWritePurchaseOrder = checkPermission(userInfo, props.type, [WRITE_PURCHASE_ORDER], organizationId);
    const isViewReport = checkPermission(userInfo, props.type, [VIEW_REPORT], organizationId);
    const { locationId } = useSelector(locationSelector);
    const isAdmin = checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId);
    const userAccessLocationOrRegion = checkUseRegionAndLocation(userInfo, organizationId, isAdmin);
    

    const handleReconcileStock = () => {
        setOpenReconcileModal(true);
        setIsEdit(false);
    };

    const handleImportShipment = () => {
        setIsEdit(true);
        setIsImportShipment(true);
    };

    const handleExportItem = async () => {
        setOpenModalExport(!openModalExport);
    };

    const handleExportLedgerItem = async () => {
        setOpenModalExportLedger(!openModalExportLedger);
    };

    const {data, isLoading, refetch, error} = useQuery(
        ['getStocks', currentPage, searchValue, sortBy, sortByType, pageCount],
        () => getStocksByFilter({page: currentPage, limit: pageCount, searchQuery: searchValue, sortBy, sortByType, locationId}),
        {
            staleTime: Infinity,
        },
    );

  useEffect(() => {
    if (error) {
      setStocks([initStock]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

    const getAllStocks = useMutation(['getAllStocks'], {
        mutationFn: getStocksByFilter,
        onSuccess: ({data}) => setAllStocks(data.entities),
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    const handleCopyToClipboard = (e: any, item: any, type: string) => {
        switch (type) {
            case 'name':
                copyToClipboard(item.item?.name, t('stockPage.copiedName'));
                break;
            case 'quantityOnHand':
                copyToClipboard(item.quantityOnHand, t('stockPage.copiedItemCount'));
                break;
            case 'units':
                copyToClipboard(item.item?.units, t('stockPage.copiedUnits'));
                break;
        }
        e.stopPropagation();
    };

    const handleHeaderSort = (field: string) => {
        setSortBy(field);
        let type = '';
        if (field !== sortBy) {
            type = sortByTypeType.ASC;
        } else if (sortByType === sortByTypeType.ASC) {
            type = sortByTypeType.DESC;
        } else {
            setSortBy(type);
        }
        setSortByType(type);
    };

    const handleViewStockLedger = async (id: string) => {
        navigate(`${routePaths.STOCK_PAGE}/${id}`);
    }

    const handleCreateOrder = () => {
        navigate(routePaths.PURCHASE_ORDER_PAGE);
    }

    useEffect(() => {
        refetch();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [locationId]);
    
    useEffect(() => {
        if (data) {
            setStocks(data?.data?.entities || []);
            setTotalEntities(data.data?.totalEntities || 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setTotalEntities(data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    return (
        <>
            {userAccessLocationOrRegion === true 
            ? 
            (
                <div className="stock-list">
                {error ? <EmptyState buttonName={t('stockPage.reconcileStock')}
                                    handleAddClick={isWriteOrg && handleReconcileStock}
                                    title={t('stockPage.titleNoResult')}
                                    subtitle={t('stockPage.subTitleNoResult')}
                                    icon={stockEmpty}
                    /> :
                    <>
                        {!isEqual(stocks[0], initStock) && totalEntities === 0 && searchValue === ''
                            ?
                            <EmptyState buttonName={t('stockPage.reconcileStock')}
                                        handleAddClick={isWriteOrg && handleReconcileStock}
                                        title={t('stockPage.titleNoResult')}
                                        subtitle={t('stockPage.subTitleNoResult')}
                                        icon={stockEmpty}
                            />
                            :
                            !isEqual(stocks[0], initStock) && totalEntities === 0
                                ?
                                (
                                    <div className="p-[30px]">
                                            <ActionTable
                                                placeholderSearch="Search"
                                                buttons={[
                                                    {
                                                        buttonName: t('stockPage.importShipment'),
                                                        buttonAction: handleImportShipment,
                                                        buttonHidden: isWriteOrg,
                                                    },
                                                    {
                                                        buttonName: t('stockPage.reconcileStock'),
                                                        buttonAction: handleReconcileStock,
                                                        buttonHidden: isWriteOrg,
                                                    },
                                                    {
                                                        buttonName: t('stockPage.createOrder'),
                                                        buttonAction: handleCreateOrder,
                                                        buttonHidden: isWritePurchaseOrder
                                                    }
                                                ]}
                                                buttonExports={isViewReport ? [
                                                    {
                                                        buttonName: t('stockPage.exportItem'),
                                                        buttonAction: handleExportItem,
                                                        buttonHidden: isViewReport,
                                                    },
                                                    {
                                                        buttonName: t('stockPage.exportStockLedger'),
                                                        buttonAction: handleExportLedgerItem,
                                                        buttonHidden: isViewReport,
                                                    }
                                                ]: undefined}
                                                handleSearch={handleSearch}
                                                searchValue={searchValue}
                                            />
                                        <NoResult/>
                                    </div>
                                )
                                :
                                (
                                    <div className="main-sub">
                                        <ActionTable
                                                placeholderSearch="Search"
                                                buttons={[
                                                    {
                                                        buttonName: t('stockPage.importShipment'),
                                                        buttonAction: handleImportShipment,
                                                        buttonHidden: isWriteOrg,
                                                    },
                                                    {
                                                        buttonName: t('stockPage.reconcileStock'),
                                                        buttonAction: handleReconcileStock,
                                                        buttonHidden: isWriteOrg,
                                                    },
                                                    {
                                                        buttonName: t('stockPage.createOrder'),
                                                        buttonAction: handleCreateOrder,
                                                        buttonHidden: isWritePurchaseOrder
                                                    }
                                                ]}
                                                buttonExports={isViewReport ? [
                                                    {
                                                        buttonName: t('stockPage.exportItem'),
                                                        buttonAction: handleExportItem,
                                                        buttonHidden: isViewReport,
                                                    },
                                                    {
                                                        buttonName: t('stockPage.exportStockLedger'),
                                                        buttonAction: handleExportLedgerItem,
                                                        buttonHidden: isViewReport,
                                                    }
                                                ] : undefined}
                                                handleSearch={handleSearch}
                                            />
                                            <Table className="border-b">
                                                <Table.Head className="text-xs text-gray-900 border-b-2 border-gray-100">
                                                    <Table.HeadCell className="cursor-pointer bg-white font-medium text-xs px-3"
                                                                    onClick={() => handleHeaderSort('name')}>
                                                        <div className="flex items-center ">
                                                            {t('stockPage.item')}
                                                            <Sort check={sortBy === 'name'} sortByType={sortByType}/>
                                                        </div>
                                                    </Table.HeadCell>
                                                    <Table.HeadCell
                                                        className="hidden-mobile w-34 bg-white font-medium text-xs px-3"
                                                        onClick={() => {
                                                            handleHeaderSort('quantityOnHand');
                                                        }}
                                                    >
                                                        <div className="flex items-center">
                                                            {t('stockPage.count')}
                                                            <Sort check={sortBy === 'quantityOnHand'} sortByType={sortByType}/>
                                                        </div>
                                                    </Table.HeadCell>
                                                    <Table.HeadCell
                                                        className="hidden-mobile w-44 bg-white font-medium text-xs px-3"
                                                        onClick={() => {
                                                            handleHeaderSort('unitCost');
                                                        }}
                                                    >
                                                        <div className="flex items-center">
                                                            {t('stockPage.unitCost')}
                                                            <Sort check={sortBy === 'unitCost'} sortByType={sortByType}/>
                                                        </div>
                                                    </Table.HeadCell>
                                                    <Table.HeadCell
                                                        className="hidden-mobile w-44 bg-white font-medium text-xs px-3"
                                                        onClick={() => {
                                                            handleHeaderSort('unitPrice');
                                                        }}
                                                    >
                                                        <div className="flex items-center">
                                                            {t('stockPage.unitPrice')}
                                                            <Sort check={sortBy === 'unitPrice'} sortByType={sortByType}/>
                                                        </div>
                                                    </Table.HeadCell>
                                                    <Table.HeadCell
                                                        className="hidden-mobile w-50 bg-white font-medium text-xs px-3"
                                                        onClick={() => {
                                                            handleHeaderSort('modified');
                                                        }}
                                                    >
                                                        <div className="flex items-center">
                                                            {t('stockPage.lastReconcile')}
                                                            <Sort check={sortBy === 'modified'} sortByType={sortByType}/>
                                                        </div>
                                                    </Table.HeadCell>
                                                </Table.Head>
                                                <Table.Body>
                                                    {stocks.map((item: stockTypes, index) => (
                                                        <Table.Row key={`row-${index + 1}`}
                                                                className="cursor-pointer bg-white text-sm hover:bg-gray-50 border-b border-gray-100">
                                                            <Table.Cell className="font-semibold py-[15px] px-3">
                                                                <div className="flex flex-row items-center"
                                                                    onClick={() => handleViewStockLedger(item.id)}>
                                                                    <div className="text-secondary">{item?.item?.name}</div>
                                                                    <div
                                                                        className="copy-icon pl-3 cursor-pointer text-gray-800">
                                                                        <HiOutlineDuplicate size={25}
                                                                                            onClick={(e: any) => handleCopyToClipboard(e, item, 'name')}/>
                                                                    </div>
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell className="py-[15px] px-3">
                                                                <div className="flex flex-row items-center"
                                                                    onClick={() => handleViewStockLedger(item.id)}>
                                                                    <div className="text-black">{item?.quantityOnHand || 0}</div>
                                                                    <div
                                                                        className="copy-icon pl-3 cursor-pointer text-gray-800">
                                                                        <HiOutlineDuplicate size={25}
                                                                                            onClick={(e: any) => handleCopyToClipboard(e, item, 'quantityOnHand')}/>
                                                                    </div>
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell className="py-[15px] px-3">
                                                                <div className="flex flex-row items-center"
                                                                    onClick={() => handleViewStockLedger(item.id)}>
                                                                    <div className="text-black">{formatMoney(item.item?.unitCost)}</div>
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell className="py-[15px] px-3">
                                                                <div className="flex flex-row items-center"
                                                                    onClick={() => handleViewStockLedger(item.id)}>
                                                                    <div className="text-black">{formatMoney(item.item?.unitPrice)}</div>
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell className="py-[15px] px-3">
                                                                <div className="flex flex-row items-center"
                                                                    onClick={() => handleViewStockLedger(item.id)}>
                                                                    <div className="text-black">{formatDateNotTime(item?.modified?.toString() || '')}</div>
                                                                </div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))}
                                                </Table.Body>
                                            </Table>
                                            {isLoading && <SpinnerComponent/>}
                                            <PaginateTable
                                                setCurrentPage={setCurrentPage}
                                                currentPage={currentPage}
                                                totalEntities={totalEntities}
                                                isLoadingTable={isLoading}
                                            />
                                    </div>
                                )
                        }
                    </>}
                    {openReconcileModal && (
                    <ReconcileStockModal
                        headerTitle={isEdit ? t('stockPage.importShipment') : t('stockPage.reconcileStock')}
                        openModal={openReconcileModal}
                        setOpenModal={setOpenReconcileModal}
                        openReconcileReviewModal={openReconcileReviewModal}
                        setOpenReconcileReviewModal={setOpenReconcileReviewModal}
                        isEdit={isEdit}
                        isImportShipment={isImportShipment}
                        targetData={targetStock}
                        dataReview={targetData}
                        setDataReview={setTargetData}
                        queryClient={queryClient}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    />
                )}
                {isImportShipment && (
                    <ImportShipmentModal
                        headerTitle={t('stockPage.importShipment')}
                        openModal={isImportShipment}
                        isEdit={isEdit}
                        isImportShipment={isImportShipment}
                        targetData={targetStock}
                        setOpenModal={setIsImportShipment}
                        queryClient={queryClient}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    />
                )}
                {openReconcileReviewModal && (
                    <ReconcileReviewModal
                        openParentModal={openReconcileReviewModal}
                        setOpenParentModal={setOpenReconcileReviewModal}
                        openRootModal={openReconcileReviewModal}
                        setOpenRootModal={setOpenReconcileReviewModal}
                        openModalReview={openReconcileReviewModal}
                        setOpenModalReview={setOpenReconcileReviewModal}
                        headerTitle={isEdit ? t('stockPage.importShipment') : t('stockPage.reconcileStock')}
                        subHeaderTitle={t('stockPage.subHeaderImportModal')}
                        isEdit={true}
                        isImportShipment={isImportShipment}
                        targetData={targetData}
                        queryClient={queryClient}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    />
                )}
                {openModalExport && (
                    <ExportStockModal
                        headerTitle={t('stockPage.exportItem')}
                        openModal={openModalExport}
                        setOpenModal={setOpenModalExport}
                    />
                )}
                {openModalExportLedger && (
                    <ExportStockLedgerModal
                        headerTitle={t('stockPage.exportStockLedger')}
                        openModal={openModalExportLedger}
                        setOpenModal={setOpenModalExportLedger}
                    />
                )}
            </div>
            ) 
            : 
            (
                <div className="h-full pt-16">
                    <div className="mb-4 flex justify-center">
                        <img alt="MileMarker logo" src={locationEmty}/>
                    </div>
                    <p className="text-2xl font-semibold text-center mb-4">{t('dashboard.notificationNotLocation')}</p>
                    <p className="text-sm font-normal text-center	">{t('dashboard.noteNotLocation')}</p>
                </div>
            )
            }
        </>
    );
};

export default StockList;
