/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import {checkPermission, formatMoney} from 'utils/utils';
import {apiStatus, defaultpageCount, permissionKeys, sortByTypeType} from 'utils/constants';
import {useSelector} from 'react-redux';
import {locationSelector, orgSelector, userSelector} from 'redux/selectors';
import useUser from 'hooks/useUser';
import ButtonBack from 'components/button/buttonBack';
import Buttons from 'components/button/Buttons';
import {Label, Table, TextInput, Tooltip} from 'flowbite-react';
import {createPurchaseOrder, getPurchaseOrderById, updatePurchaseOrderById} from 'api/purchaseOrderApi';
import {getItemById, getItemsByCategory, getItemsByFilter} from 'api/itemApi';
import EmptyState from 'components/commonComponent/emptyState';
import {v4 as uuidv4} from 'uuid';
import {purchaseOrderItemType} from 'utils/proptypes';
import MultiSelect from 'components/select/index';
import {cloneDeep, concat, orderBy, sumBy} from 'lodash';
import GroupButtonWithIcons from 'components/button/buttonGroupWithIcons';
import {HiOutlineExclamationCircle, HiOutlineTrash} from 'react-icons/hi';
import {toast} from 'react-toastify';
import './purchaseOrder.scss';
import CreateItemByCategoryModal from 'components/modal/purchaseOrderModal/createItemByCategoryModal';
import {initOrder} from 'utils/initData';
import {getLocationById} from 'api/locationApi';
import axios from 'axios';
import urls from 'api/urls';
import StatisticsSaleModal from 'components/modal/purchaseOrderModal/statisticsSaleModal';
import {getStockByItemAndLocation, statisticsSaleItem} from 'api/stockApi';
import Sort from 'components/table/sort';

type orderN2GProps = {
    order: any
};

const PurchaseOrderDetail = (props: any) => {
    const {type} = props;
    const {WRITE_PURCHASE_ORDER, PLATFORM_ADMIN} = permissionKeys;
    const location = useLocation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const params: any = useParams();
    const [data, setData] = useState([]);
    const [itemTypeOptions, setItemTypeOptions] = useState([{label: '...', value: null}]);
    const {userInfo} = useSelector(userSelector);
    const {organizationId} = useSelector(orgSelector);
    const {locationId} = useSelector(locationSelector);
    const {fetchCurrentUser} = useUser();
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(defaultpageCount);
    const [totalEntities, setTotalEntities] = useState(0);
    const [t] = useTranslation();
    const [sortBy, setSortBy] = useState('');
    const [sortByType, setSortByType] = useState('');
    const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_PURCHASE_ORDER], organizationId);
    const itemEmpty = require('../../assets/image/svg/Emptys.svg').default || '';
    const [openModal, setOpenModal] = useState(false);
    const [openModalStatistics, setOpenModalStatistics] = useState(false);
    const [category, setCategory] = useState([]);
    const [targetPurchaseOrder, setTargetPurchaseOrder] = useState(initOrder);
    const [dataChart, setDataChart] = useState<any>(null);
    const [itemNameChart, setItemNameChart] = useState('');
    const [errorsN2G, setErrorsN2G] = useState<any>(null);
    const [orderTotalNew, setOrderTotalNew] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);

    useEffect(() => {
        if (params.id !== 'blank-new') {
            getPurchaseOrderById(params?.id).then((res: any) => {
                setTargetPurchaseOrder(res.data);
                setTotalItem(res.data?.purchaseOrderItems?.length);
                if (data.length === 0) {
                    setData(
                        res.data?.purchaseOrderItems?.map((item: any) => {
                            return {
                                id: item.id,
                                itemId: item.itemId,
                                item: item.item,
                                orderCount: item.quantityOrdered,
                                quantityOnHand: item.quantityOnHand,
                                unitSoldLast7: item.unitSoldLast7,
                                sixWeekAvg: item.sixWeekAvg
                            };
                        }),
                    );
                }
                if (data?.length !==  res.data?.purchaseOrderItems?.length) {
                    handleSortData();
                } else {
                    setData(
                        res.data?.purchaseOrderItems?.map((item: any) => {
                            return {
                                id: item.id,
                                itemId: item.itemId,
                                item: item.item,
                                orderCount: item.quantityOrdered,
                                quantityOnHand: item.quantityOnHand,
                                unitSoldLast7: item.unitSoldLast7,
                                sixWeekAvg: item.sixWeekAvg
                            };
                        }),
                    );
                    handleSortData();
                }
                if (res.data?.status === 'Draft' || res.data?.status === 'Requested') {
                    //validate data to n2g
                    getLocationAndConvertLocationN2G(locationId).then((resLocation: any) => { 
                        const validateData: orderN2GProps = {
                            order: {
                                customer: getCustomerN2G(),
                                location: resLocation,
                                items: convertItemToItemN2G(res.data?.purchaseOrderItems)
                            }
                        };
                        const url = `${urls.purchaseOrder.validateOrderN2G}`;
                        axios.post(url, validateData, {headers: {'Access-Control-Allow-Origin': url, 'Accept': '*/*'}})
                            .then(response => {
                                setErrorsN2G(null);
                            })
                            .catch(error => {
                                if (error?.response?.data?.errors || error?.response?.data?.location) {
                                    const errorsItems: any = [];
                                    for (let index = 0; index < error.response.data?.errors?.length; index++) {
                                        const element = error.response.data?.errors[index];
                                        for (let index = 0; index < element.errors?.length; index++) {
                                            const elementMsg = element.errors[index];
                                            elementMsg.sku = element.sku;
                                            if (element.quantityAvailable !== undefined) {
                                                elementMsg.quantityAvailable = element.quantityAvailable;
                                            }
                                            errorsItems.push(elementMsg);
                                        }
                                    }
                                    const errorsLocation = error.response.data?.location?.errors;
                                    const errorsList: any = {
                                        location: errorsLocation,
                                        items: errorsItems
                                    }
                                    setErrorsN2G(errorsList);
                                }
                            });
                    });
                }
            });
        } else {
            if (data?.length > 0) {
                handleSortData();
            } else {
                setData([]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.id, sortBy, sortByType]);

    const handleSortData = () => {
        if (sortBy && sortByType) {
            const sortType = sortByType === sortByTypeType.ASC ? 'asc' : 'desc';
            let sortedList: any = [];
            switch (sortBy) {
                case 'itemName':
                    sortedList = orderBy(data, ['item.name'], sortType);
                    break;
                case 'itemSku':
                    sortedList = orderBy(data, ['item.sku'], sortType);
                    break;
                case 'quantityOrdered':
                    sortedList = orderBy(data, ['orderCount'], sortType);
                    break;
                case 'itemCaseCost':
                    sortedList = orderBy(data, ['item.caseCost'], sortType);
                    break;
            }
            setData(sortedList);
        }
    }
	
	
	const handleHeaderSort = (field: string) => {
        setSortBy(field);
        let type = '';
        if (field !== sortBy) {
            type = sortByTypeType.ASC;
        } else if (sortByType === sortByTypeType.ASC) {
            type = sortByTypeType.DESC;
        } else {
            setSortBy(type);
        }
        setSortByType(type);
    };

    useEffect(() => {
        if (category.length > 0) {
            let ids: string = '';
            for (let index = 0; index < category.length; index++) {
                const id = category[index];
                if (ids === '') {
                    ids = id;
                } else {
                    ids = `${ids},${id}`;
                }
            }
            getItemsByCategory({categoryIds: ids, locationId}).then((res: any) => {
                if (res.data.totalEntities > 0) {
                    setData(
                        res.data.entities.map((item: any) => {
                            return {id: uuidv4(), itemId: item.id, item: item, orderCount: 1, quantityOnHand: item.quantityOnHand, unitSoldLast7: item.unitSoldLast7, sixWeekAvg: item.sixWeekAvg};
                        }),
                    );
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    useEffect(() => {
        const pageSize = 100;
        getItemsByFilter({ page: 0, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, isActive: true, locationId })?.then(res => {
            const totalCount = res?.data?.totalEntities;
            if (totalCount === 0) {
                setItemTypeOptions([]);
            } else {
                const pageBreak = Math.ceil(totalCount / pageSize);
                let listTemps: any = [];
                for (let index = 0; index < pageBreak; index++) {
                    // eslint-disable-next-line
                    getItemsByFilter({ page: index, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, isActive: true, locationId })?.then(resPage => {
                        if (listTemps.length === 0) {
                            listTemps = resPage?.data?.entities;
                        } else {
                            listTemps = listTemps.concat(resPage?.data?.entities);
                        }
      
                        if (listTemps.length > 0) {
                            const newOption = listTemps.map((i: any) => ({label: i.name, value: {id: i.id, upcs: i.upcs}}));
                            setItemTypeOptions(newOption);
                        }
                      });
                }
            }
        });

        // eslint-disable-next-line
    }, []);

    const handleSubmitPurchaseOrder = async (items: any) => {
        let check = true;
        for (let index = 0; index < items.length; index++) {
            const rowItem: any = items[index];
            if (rowItem) {
                if (rowItem.itemId === '') {
                    check = false;
                } else {
                    if (rowItem.orderCount === 0) {
                        check = false;
                    }
                }
            }
        }

        if (errorsN2G) {
            if (errorsN2G.items && errorsN2G.items.length > 0) {
                check = false;
            }

            if (errorsN2G.location && errorsN2G.location.length > 0) {
                check = false;
            }
        }

        if (check === true) {
            let payload: any;
            if (targetPurchaseOrder.id !== '') {
                payload = {
                    name: targetPurchaseOrder.name,
                    locationId: locationId,
                    location: {id: locationId},
                    status: 'Requested',
                    requestedOn: new Date(),
                    orderedOn: new Date(),
                    rejectedOn: new Date(),
                    receivedOn: new Date(),
                    requestedBy: userInfo.id,
                    requestedUser: {id: userInfo.id},
                    purchaseOrderItems: items.map((i: any) => {
                        return {
                            id: i.id,
                            itemId: i.itemId,
                            item: i.item,
                            quantityOrdered: i.orderCount,
                            quantityReceived: 0
                        }
                    })
                }
            } else {
                payload = {
                    name: `Order By ${userInfo.firstName} ${userInfo.lastName}`,
                    locationId: locationId,
                    location: {id: locationId},
                    status: 'Requested',
                    requestedOn: new Date(),
                    orderedOn: new Date(),
                    rejectedOn: new Date(),
                    receivedOn: new Date(),
                    requestedBy: userInfo.id,
                    requestedUser: {id: userInfo.id},
                    purchaseOrderItems: items.map((i: any) => {
                        return {itemId: i.itemId, item: i.item, quantityOrdered: i.orderCount, quantityReceived: 0};
                    }),
                };
            }

            const res = targetPurchaseOrder.id !== '' ? await updatePurchaseOrderById(targetPurchaseOrder.id, payload) : await createPurchaseOrder(payload);
            if (res.status >= apiStatus.SUCCESS) {
                const message: string = targetPurchaseOrder.id !== '' ? t('orderPage.editSuccess') : t('orderPage.saveSuccess');
                toast.success(message);
                fetchCurrentUser();
                navigate(-1);
            } else {
                toast.error(res.statusText);
                fetchCurrentUser();
            }
        } else {
            if (errorsN2G?.location && errorsN2G?.location?.length > 0) {
                toast.error(`Location Error: ${buildErrorMessageN2G(errorsN2G.location)}`);
            }
            toast.error(`${t('stockPage.requiredFieldMessage')}`);
        }
    };

    const handleSaveDraft = async (items: any) => {
        let payload: any;
            if (targetPurchaseOrder.id !== '') {
                payload = {
                    name: targetPurchaseOrder.name,
                    locationId: locationId,
                    location: {id: locationId},
                    status: 'Draft',
                    requestedOn: new Date(),
                    orderedOn: new Date(),
                    rejectedOn: new Date(),
                    receivedOn: new Date(),
                    requestedBy: userInfo.id,
                    requestedUser: {id: userInfo.id},
                    purchaseOrderItems: items.map((i: any) => {
                        return {
                            id: i.id,
                            itemId: i.itemId,
                            item: i.item,
                            quantityOrdered: i.orderCount,
                            quantityReceived: 0
                        }
                    })
                }
            } else {
                payload = {
                    name: `Order By ${userInfo.firstName} ${userInfo.lastName}`,
                    locationId: locationId,
                    location: {id: locationId},
                    status: 'Draft',
                    requestedOn: new Date(),
                    orderedOn: new Date(),
                    rejectedOn: new Date(),
                    receivedOn: new Date(),
                    requestedBy: userInfo.id,
                    requestedUser: {id: userInfo.id},
                    purchaseOrderItems: items.map((i: any) => {
                        return {itemId: i.itemId, item: i.item, quantityOrdered: i.orderCount, quantityReceived: 0};
                    }),
                };
            }

            const res = targetPurchaseOrder.id !== '' ? await updatePurchaseOrderById(targetPurchaseOrder.id, payload) : await createPurchaseOrder(payload);
            if (res.status >= apiStatus.SUCCESS) {
                const message: string = targetPurchaseOrder.id !== '' ? t('orderPage.editSuccess') : t('orderPage.saveSuccess');
                toast.success(message);
                fetchCurrentUser();
                navigate(-1);
            } else {
                toast.error(res.statusText);
                fetchCurrentUser();
            }
    };

    const handleAddRow = (item: any) => {
        const newRow: purchaseOrderItemType = {
            // Initialize the properties of the new row here
            // For example:
            id: uuidv4(),
            itemId: '',
            item: null,
            orderCount: 1,
            quantityOnHand: 0,
            unitSoldLast7: 0,
            sixWeekAvg: 0
        };
        const newData: any = concat(data, newRow);
        setData(newData);
    };

    const handleAddItemByCategory = () => {
        setOpenModal(!openModal);
    };

    const addRowFunc = () => {
        let check = true;
        for (let index = 0; index < data.length; index++) {
            const rowItem: any = data[index];
            if (rowItem) {
                if (rowItem.itemId === '') {
                    check = false;
                } else {
                    if (rowItem.orderCount === 0) {
                        check = false;
                    }
                }
            }
        }

        if (check === false) {
            toast.error(`${t('stockPage.requiredFieldMessage')}`);
        } else {
            const newRow: purchaseOrderItemType = {
                // Initialize the properties of the new row here
                // For example:
                id: uuidv4(),
                itemId: '',
                item: null,
                orderCount: 1,
                quantityOnHand: 0,
                unitSoldLast7: 0,
                sixWeekAvg: 0
            };
            const newData: any = concat(data, newRow);
            setData(newData);
        }
    };

    const handleDeleteRow = (id: string, sku: string) => {
        const newData = data?.filter((d: any) => d.id !== id);
        setData(newData);
        let sum = 0;
        for (let index = 0; index < newData.length; index++) {
            const element: any = newData[index];
            sum = sum + ((element.orderCount || 0) * (element.item?.caseCost || 0));
        }
        targetPurchaseOrder.orderTotal = sum;
        setTotalItem(newData?.length);
        const newItemsErrorsN2G = errorsN2G?.items?.filter((e: any) => e.sku !== sku);
        const newErrorsN2G = {
            location: errorsN2G?.location,
            items: newItemsErrorsN2G
        }
        setErrorsN2G(newErrorsN2G);
    };

    const handleStatictisItem = async (id: string, name: string) => {
        const res = await statisticsSaleItem(id, locationId, 28);
        if (res.data) {
            setDataChart(res.data);
        }
        setItemNameChart(name);
        setOpenModalStatistics(!openModalStatistics);
    };

    const handleChangeItem = async (id: string, option: any, field: any) => {
        const listContentClone = cloneDeep(data);
        const findContent: any = listContentClone?.find((item: any) => item.id === id);
        findContent[field] = option ? option.value?.id : '';
        const res = await getItemById(option ? option.value?.id : '');
        if (res.data) {
            findContent['item'] = res.data;
            findContent['orderCount'] = 1;
        }
        const resStock = await getStockByItemAndLocation(locationId, option ? option.value?.id : '');
        if (resStock) {
            findContent["quantityOnHand"] = resStock.data?.quantityOnHand;
        }
        const resSales7 = await statisticsSaleItem(option ? option.value?.id : '', locationId, 7);
        if (resSales7) {
            findContent["unitSoldLast7"] = resSales7.data?.count;
        }
        const resSales6w = await statisticsSaleItem(option ? option.value?.id : '', locationId, 42);
        if (resSales6w) {
            findContent["sixWeekAvg"] = resSales6w.data?.count;
        }
        setData(listContentClone);
        let sum = 0;
            for (let index = 0; index < listContentClone.length; index++) {
                const element: any = listContentClone[index];
                sum = sum + ((element.orderCount || 0) * (element.item?.caseCost || 0));
            }
        if (params?.id !== 'blank-new') {
            targetPurchaseOrder.orderTotal = sum;
        } else {
            
            setOrderTotalNew(sum);
        }
        setTotalItem(listContentClone?.length);

        const validateData: orderN2GProps = {
            order: {
                customer: getCustomerN2G(),
                location: await getLocationAndConvertLocationN2G(locationId),
                items: convertItemToItemN2G(listContentClone)
            }
        };

        const url = `${urls.purchaseOrder.validateOrderN2G}`;
        axios.post(url, validateData, {headers: {'Access-Control-Allow-Origin': url, 'Accept': '*/*'}})
            .then(response => {
                setErrorsN2G(null);
            })
            .catch(error => {
                if (error?.response?.data?.errors || error?.response?.data?.location) {
                    const errorsItems: any = [];
                    for (let index = 0; index < error.response.data?.errors?.length; index++) {
                        const element = error.response.data?.errors[index];
                        for (let index = 0; index < element.errors?.length; index++) {
                            const elementMsg = element.errors[index];
                            elementMsg.sku = element.sku;
                            if (element.quantityAvailable !== undefined) {
                                elementMsg.quantityAvailable = element.quantityAvailable;
                            }
                            errorsItems.push(elementMsg);
                        }
                    }
                    const errorsLocation = error.response.data?.location?.errors;
                    const errorsList: any = {
                        location: errorsLocation,
                        items: errorsItems
                    }
                    setErrorsN2G(errorsList);
                }
            });
    };

    const handleChangeContentOverview = async (id: string, value: any, field: any) => {
        const listContentClone = cloneDeep(data);
        const findContent: any = listContentClone?.find((item: any) => item.id === id);
        if (field === 'orderCount') {
            const item = findContent['item'];
            findContent[field] = parseInt(value);
        }
        setData(listContentClone);
        let sum = 0;
            for (let index = 0; index < listContentClone.length; index++) {
                const element: any = listContentClone[index];
                sum = sum + ((element.orderCount || 0) * (element.item?.caseCost || 0));
            }
        if (params?.id !== 'blank-new') {
            targetPurchaseOrder.orderTotal = sum;
        } else {
            
            setOrderTotalNew(sum);
        }
        setTotalItem(listContentClone?.length);

        const validateData: orderN2GProps = {
            order: {
                customer: getCustomerN2G(),
                location: await getLocationAndConvertLocationN2G(locationId),
                items: convertItemToItemN2G(listContentClone)
            }
        };
        const url = `${urls.purchaseOrder.validateOrderN2G}`;
        axios.post(url, validateData, {headers: {'Access-Control-Allow-Origin': url, 'Accept': '*/*'}})
            .then(response => {
                setErrorsN2G(null);
            })
            .catch(error => {
                if (error?.response?.data?.errors || error?.response?.data?.location) {
                    const errorsItems: any = [];
                    for (let index = 0; index < error.response.data?.errors?.length; index++) {
                        const element = error.response.data?.errors[index];
                        for (let index = 0; index < element.errors?.length; index++) {
                            const elementMsg = element.errors[index];
                            elementMsg.sku = element.sku;
                            if (element.quantityAvailable !== undefined) {
                                elementMsg.quantityAvailable = element.quantityAvailable;
                            }
                            errorsItems.push(elementMsg);
                        }
                    }
                    const errorsLocation = error.response.data?.location?.errors;
                    const errorsList: any = {
                        location: errorsLocation,
                        items: errorsItems
                    }
                    setErrorsN2G(errorsList);
                }
            });
    };

    const convertItemToItemN2G = (items: any) => {
        return items?.map((x: any) => {
            return {
                sku: x.item?.sku,
                quantity: x.orderCount
            }
        });
    };

    const getLocationAndConvertLocationN2G = async (locationId: string) => {
        const res = await getLocationById(locationId);
        const location = res?.data;

        const {NODE_ENV} = process.env;

        return {
            name: NODE_ENV === "production" ? `${location?.organization?.name} ${location?.clubCode}` : `[TEST] ${location?.organization?.name} ${location?.clubCode}`,
            clubCode: location?.clubCode,
            address: location?.streetAddress1,
            address2: location?.streetAddress2,
            city: location?.city,
            state: location?.state,
            zip: location?.zipCode,
            country: "United States"
        }
    };

    const getCustomerN2G = () => {
        return {
            name: targetPurchaseOrder?.requestedUser ? `${targetPurchaseOrder.requestedUser?.firstName} ${targetPurchaseOrder.requestedUser?.lastName}` : `${userInfo.firstName} ${userInfo.lastName}`,
            email: targetPurchaseOrder?.requestedUser ? targetPurchaseOrder.requestedUser?.emailAddress : userInfo.emailAddress
        }
    }

    const buildErrorMessageN2G = (errors: any) => {

        let message = '';
        for (let index = 0; index < errors.length; index++) {
            const element = errors[index];
            if (message === '') {
                message = element?.message;
            } else {
                message += element.message;
            }
        }
        ;

        return message;
    };

    const getErrorItemN2G = (sku: string) => {
        let message = '';
        const errorItemBySku = errorsN2G?.items?.find((i: any) => i.sku === sku);
        if (errorItemBySku) {
            message = errorItemBySku.quantityAvailable !== undefined ? t('orderPage.itemN2GOutOfStock').replace('%quantity%', errorItemBySku.quantityAvailable) : errorItemBySku.message;
        }

        return message;
    }

    const customFilterOption = (option: any, inputValue: any) => {
        const label = option.label?.toLowerCase();
        const upc = option.value?.upcs?.find((x: any) => x?.upcCode?.includes(inputValue))?.upcCode?.toLowerCase();
        const input = inputValue?.toLowerCase();
    
        return label?.includes(input) || upc?.includes(input);
    };
    

    return (
        <>
            <div className="flex flex-col w-full">
                <div className="flex relative w-full p-[15px] border-b border-gray-200 justify-between items-center">
                    <ButtonBack buttonName={t('sidebar.purchaseOrder')}/>
                    <div className="text-primary text-lg font-semibold text-center">
                {params.id !== 'blank-new'
                ?
                    <>
                        <div className="text-lg">
                            <span className="gr">
                                <span className="font-normal">
                                    {t('orderPage.orderId').toUpperCase()}: &nbsp;
                                </span>
                                <span>{targetPurchaseOrder.orderN2GId ? targetPurchaseOrder.orderN2GId : targetPurchaseOrder.id}&nbsp;</span>
                                <span className="font-normal">({new Intl.NumberFormat('en-US').format(
                                    totalItem,
                                )} items)</span>&nbsp;| &nbsp;
                            </span>
                            <span className="gr">
                                <span className="font-normal">
                                    {t('orderPage.total').toUpperCase()}:
                                </span>
                                <span>{formatMoney(targetPurchaseOrder.orderTotal || 0)}</span>
                            </span>
                        </div>
                    </>
                : 
                `${t('orderPage.newPurchaseOrder')} | ${t('orderPage.total').toUpperCase()}: ${formatMoney(orderTotalNew || 0)}`}
                </div>
                <div className="flex gap-3 w-[157px]">{}</div>
            </div>
                <>
                    {data && data.length === 0 ? (
                        <EmptyState
                            buttonName={t('itemPage.addItem')}
                            handleAddClick={isWriteOrg && handleAddRow}
                            title={t('stockPage.titleNoItemResult')}
                            subtitle={t('itemPage.subTitleNoResult')}
                            icon={itemEmpty}
                        />
                    ) : (
                        <div className="purchase-order-detail">
                            <Table>
                                <Table.Head className="text-gray-900 border-b border-gray-100 border-b-2 w-full">
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium text-xs item px-0 pr-3 py-2"
                                        onClick={() => handleHeaderSort('itemName')}>
                                            <div className="flex items-center ">
                                                {t('itemPage.item')}
                                                <Sort check={sortBy === 'itemName'} sortByType={sortByType}/>
                                            </div>
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium text-xs upc px-3 py-0"
                                        onClick={() => handleHeaderSort('itemSku')}>
                                            <div className="flex items-center ">
                                                {t('itemPage.sku').toUpperCase()}
                                                <Sort check={sortBy === 'itemSku'} sortByType={sortByType}/>
                                            </div>
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium text-xs unit-pc px-3 py-0">
                                        {t('stockPage.count').toLocaleUpperCase()}
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium text-xs unit-pc px-3 py-0">
                                        {t('orderPage.unitSoldLast7').toLocaleUpperCase()}
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium text-xs unit-pc px-3 py-0">
                                        {t('orderPage.6weekavg').toLocaleUpperCase()}
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium text-xs cases px-3 py-0"
                                        onClick={() => handleHeaderSort('quantityOrdered')}>
                                            <div className="flex items-center ">
                                                {t('orderPage.cases').toUpperCase()}
                                                <Sort check={sortBy === 'quantityOrdered'} sortByType={sortByType}/>
                                            </div>
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium text-xs cost-pc px-3 py-0"
                                        onClick={() => handleHeaderSort('itemCaseCost')}>
                                            <div className="flex items-center ">
                                                {t('orderPage.costPerCase').toLocaleUpperCase()}
                                                <Sort check={sortBy === 'itemCaseCost'} sortByType={sortByType}/>
                                            </div>
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium text-xs unit-pc px-3 py-0">
                                        {t('orderPage.unitPerCase').toLocaleUpperCase()}
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium text-xs total px-3 py-0">
                                        {t('orderPage.totalUnit').toLocaleUpperCase()}
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium text-xs order px-3 py-0">{t('orderPage.totalOrder')}</Table.HeadCell>
                                    <Table.HeadCell
                                        className=" bg-white font-medium text-xs action px-3 py-0">{''}</Table.HeadCell>
                                </Table.Head>
                                <Table.Body
                                    // className={cn('', {
                                    //   '--overflow': data?.length > 6,
                                    // })}
                                >
                                    {data.map((item: purchaseOrderItemType, index) => {
                                        return (
                                            <Table.Row key={`row-${item.id}`}
                                                       className="cursor-pointer bg-white text-sm hover:bg-gray-50 border-b border-gray-100">
                                                {item.itemId === '' ?
                                                    (
                                                        <Table.Cell className="px-0 pr-3 py-3 item">
                                                            <MultiSelect
                                                                onChange={(e: any) => {
                                                                    handleChangeItem(item?.id, e, 'itemId');
                                                                }}
                                                                options={itemTypeOptions}
                                                                value={itemTypeOptions.find((i: any) => i.value?.id === item.itemId)}
                                                                id={`item-${index}`}
                                                                name={`item-${index}`}
                                                                noOptionsMessage={() => t('No options')}
                                                                closeMenuOnSelect
                                                                isHolderLabel={false}
                                                                isDisabled={
                                                                    targetPurchaseOrder.status === 'Ordered' ||
                                                                    targetPurchaseOrder.status === 'Rejected' ||
                                                                    targetPurchaseOrder.status === 'Received'
                                                                        ? true
                                                                        : false
                                                                }
                                                                filterOption={customFilterOption}
                                                            />
                                                            <div
                                                                className={`text-red-600 text-xs font-normal mt-1 warning`}>{t('userManagementPage.requiredField')}</div>
                                                        </Table.Cell>
                                                    ) :
                                                    (
                                                        <Tooltip
                                                            content={itemTypeOptions.find((i: any) => i.value?.id === item.itemId) ? itemTypeOptions.find((i: any) => i.value?.id === item.itemId)?.label : ""}>
                                                            <Table.Cell className="px-0 pr-3 py-3 item">
                                                                <MultiSelect
                                                                    onChange={(e: any) => {
                                                                        handleChangeItem(item?.id, e, 'itemId');
                                                                    }}
                                                                    options={itemTypeOptions}
                                                                    value={itemTypeOptions.find((i: any) => i.value?.id === item.itemId)}
                                                                    id={`item-${index}`}
                                                                    name={`item-${index}`}
                                                                    noOptionsMessage={() => t('No options')}
                                                                    closeMenuOnSelect
                                                                    isHolderLabel={false}
                                                                    isDisabled={
                                                                        targetPurchaseOrder.status === 'Ordered' ||
                                                                        targetPurchaseOrder.status === 'Rejected' ||
                                                                        targetPurchaseOrder.status === 'Received'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    filterOption={customFilterOption}
                                                                />
                                                            </Table.Cell>
                                                        </Tooltip>
                                                    )
                                                }
                                                <Table.Cell className="px-3 py-3 upc">
                                                    <Label>{item?.item?.sku}</Label>
                                                    {getErrorItemN2G(item?.item?.sku) !== ''
                                                        ?
                                                        (<div
                                                            className={`text-red-600 text-xs font-normal mt-1 warning`}>{getErrorItemN2G(item?.item?.sku)}</div>)
                                                        :
                                                        ('')
                                                    }
                                                </Table.Cell>
                                                <Table.Cell className="px-3 py-3 unit-pc">
                                                    <Label>{item?.quantityOnHand || 0}</Label>
                                                </Table.Cell>
                                                <Table.Cell className="px-3 py-3 unit-pc">
                                                    <Label>{item?.unitSoldLast7 || 0}</Label>
                                                </Table.Cell>
                                                <Table.Cell className="px-3 py-3 unit-pc">
                                                    <Label>{item?.sixWeekAvg || 0}</Label>
                                                </Table.Cell>
                                                <Table.Cell className="px-3 py-3 cases">
                                                    <TextInput
                                                        type="number"
                                                        className="custom-input-count"
                                                        id={`orderCount-${index}`}
                                                        name={`orderCount-${index}`}
                                                        value={item?.orderCount}
                                                        onChange={(e: any) => {
                                                            handleChangeContentOverview(item?.id, e.target.value, 'orderCount');
                                                        }}
                                                        disabled={
                                                            targetPurchaseOrder.status === 'Ordered' ||
                                                            targetPurchaseOrder.status === 'Rejected' ||
                                                            targetPurchaseOrder.status === 'Received'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {item.orderCount === 0 ? (
                                                        <div
                                                            className={`text-red-600 text-xs font-normal mt-1 warning`}>{t('userManagementPage.requiredField')}</div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="px-3 py-3 cost-pc">
                                                    <Label>{formatMoney(item?.item?.caseCost)}</Label>
                                                </Table.Cell>
                                                <Table.Cell className="px-3 py-3 unit-pc">
                                                    <Label>{item?.item?.upcs?.find((x: any) => x.default === true)?.caseQuantity}</Label>
                                                </Table.Cell>
                                                <Table.Cell className="px-3 py-3 total">
                                                    <Label>{item?.orderCount ? item?.orderCount * item?.item?.upcs?.find((x: any) => x.default === true)?.caseQuantity : 0}</Label>
                                                </Table.Cell>
                                                <Table.Cell className="px-3 py-3 order">
                                                    <Label>{formatMoney((item?.orderCount || 0) * (item?.item?.caseCost))}</Label>
                                                </Table.Cell>
                                                <Table.Cell className="px-3 py-3 action">
                                                    <GroupButtonWithIcons
                                                        className="items-center justify-center"
                                                        buttons={[
                                                            {
                                                                type: 'button',
                                                                text: '',
                                                                classType: 'white',
                                                                icon: <HiOutlineTrash size={'20px'}/>,
                                                                disabled:
                                                                    params.id === 'blank-new'
                                                                        ? false
                                                                        : targetPurchaseOrder.status === 'Ordered' ||
                                                                        targetPurchaseOrder.status === 'Rejected' ||
                                                                        targetPurchaseOrder.status === 'Received'
                                                                            ? true
                                                                            : false,
                                                                action: () => {
                                                                    handleDeleteRow(item?.id, item?.item?.sku);
                                                                },
                                                            },
                                                            {
                                                                type: 'button',
                                                                text: '',
                                                                classType: 'white',
                                                                icon: <HiOutlineExclamationCircle size={'20px'}/>,
                                                                action: () => {
                                                                    handleStatictisItem(item?.item?.id, item?.item?.name);
                                                                },
                                                            }
                                                        ]}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                            {params.id === 'blank-new' ? (
                                <div className="group-button-footer">
                                    <div className="left">
                                        <Buttons className="text-secondary border border-secondary"
                                                 onClick={() => addRowFunc()} text={t('orderPage.addSingleItem')}/>
                                        <Buttons
                                            className="text-secondary border border-secondary"
                                            onClick={() => handleAddItemByCategory()}
                                            text={t('orderPage.addItemBycategory')}
                                        />
                                    </div>
                                    <div
                                        className="right">
                                        <Buttons className="text-white bg-gray-400"
                                                 onClick={() => handleSaveDraft(data)}
                                                 text={t('orderPage.saveDraft')}/>
                                        <Buttons className="text-white bg-primary"
                                                 onClick={() => handleSubmitPurchaseOrder(data)}
                                                 text={t('orderPage.submitReview')}/>
                                        <Buttons className="text-white bg-red-500" onClick={() => navigate(-1)}
                                                 text={t('orderPage.cancel')}/>
                                    </div>
                                </div>
                            ) : targetPurchaseOrder.status === 'Requested' || targetPurchaseOrder.status === 'Draft' ? (
                                <div className="group-button-footer">
                                    <div className="left">
                                        <Buttons className="text-secondary border border-secondary"
                                                 onClick={() => addRowFunc()} text={t('orderPage.addSingleItem')}/>
                                        <Buttons
                                            className="text-secondary border border-secondary"
                                            onClick={() => handleAddItemByCategory()}
                                            text={t('orderPage.addItemBycategory')}
                                        />
                                    </div>
                                    <div
                                        className="right">
                                        <Buttons className="text-white bg-gray-400"
                                                 onClick={() => handleSaveDraft(data)}
                                                 text={t('orderPage.saveDraft')}/>
                                        <Buttons className="text-white bg-primary"
                                                 onClick={() => handleSubmitPurchaseOrder(data)}
                                                 text={t('orderPage.submitReview')}/>
                                        <Buttons className="text-white bg-red-500" onClick={() => navigate(-1)}
                                                 text={t('orderPage.cancel')}/>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )}
                </>
            </div>
            {openModal && (
                <CreateItemByCategoryModal
                    headerTitle={t('categoryPage.chooseCategory')}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    category={category}
                    setCategory={setCategory}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
            {openModalStatistics && (
                <StatisticsSaleModal
                    headerTitle={t('stockPage.statisticsSale')}
                    openModal={openModalStatistics}
                    setOpenModal={setOpenModalStatistics}
                    itemName={itemNameChart}
                    data={dataChart}
                />
            )}
        </>
    );
};

export default PurchaseOrderDetail;
