/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import {useMutation} from '@tanstack/react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import {toast} from 'react-toastify';
import {checkOrgAdmin, checkPermission, getUserOrg, messageErrors} from 'utils/utils';
import useUser from 'hooks/useUser';
import {Label, Modal, Radio, Table, TextInput} from 'flowbite-react';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {useEffect, useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './itemModal.scss';
import MultiSelect from 'components/select/index';
import InputText from 'components/commonComponent/inputText';
import {orgSelector, userSelector} from 'redux/selectors';
import {useSelector} from 'react-redux';
import {getCategoriesByFilter} from 'api/categoryApi';
import {pageType, permissionKeys, sortByTypeType, defaultpageCount} from 'utils/constants';
import {createItem, updateItemById} from 'api/itemApi';
import GroupButtonWithIcons from 'components/button/buttonGroupWithIcons';
import {HiOutlineBan, HiOutlinePencil, HiOutlineRefresh, HiOutlineTrash, HiPlus} from 'react-icons/hi';
import {v4 as uuidv4} from 'uuid';
import {cloneDeep, concat} from 'lodash';
import { getRegionByFilter, getChildrenRegionByParent } from 'api/regionApi';

type itemModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    targetData?: any,
    isEdit: boolean,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const ItemModal = (props: itemModalProps) => {
    const {openModal, targetData, isEdit, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage} = props;
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const rootRef = useRef(null);
    const {userInfo} = useSelector(userSelector);
    const {WRITE_USER, PLATFORM_ADMIN} = permissionKeys;
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [categoryTypeOptions, setCategoryTypeOptions] = useState([{label: '...', value: ''}]);
    const [error, setErrors] = useState(false);
    const {organizationId} = useSelector(orgSelector);
    const [upcs, setUpcs] = useState<any>([]);
    const [regionTypeOptions, setRegionTypeOptions] = useState([{label: '...', value: ''}]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const isAdmin = checkPermission(userInfo, pageType.ORGANIZATION, [PLATFORM_ADMIN], organizationId);
    const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);

    const ValidateSchema = Yup.object().shape({
        name: Yup.string().required(t('userManagementPage.requiredField')).max(150, t('locationModal.maxLength150')),
        sku: Yup.string().required(t('requiredField')),
    });

    const hookForm = useForm({
        mode: 'onChange',
        resolver: yupResolver(ValidateSchema),
    });

    const {register, handleSubmit, setValue, clearErrors, setError, reset, getValues} = hookForm;

    useEffect(() => {
        const pageSize = 100;
        getCategoriesByFilter({ page: 0, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId })?.then(res => {
            const totalCount = res?.data?.totalEntities;
            if (totalCount === 0) {
              setCategoryTypeOptions([]);
            } else {
              const pageBreak = Math.ceil(totalCount / pageSize);
              let listTemps: any = [];
              for (let index = 0; index < pageBreak; index++) {
                // eslint-disable-next-line
                getCategoriesByFilter({ page: index, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId })?.then(resPage => {
                  if (listTemps.length === 0) {
                    listTemps = resPage?.data?.entities;
                  } else {
                    listTemps = listTemps.concat(resPage?.data?.entities);
                  }
      
                  if (listTemps.length > 0) {
                    const newOption = listTemps.map((i: any) => ({ label: i.name, value: i.id }));
                    setCategoryTypeOptions(newOption);
                  }
                });
              }
            }
          });

        getRegionByFilter({page: 0, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId})?.then(res => {
            const totalCount = res?.data?.totalEntities;
            if (totalCount === 0) {
                setRegionTypeOptions([]);
            } else {
                const pageBreak = Math.ceil(totalCount / pageSize);
                let listTemps: any = [];
                for (let index = 0; index < pageBreak; index++) {
                    // eslint-disable-next-line
                    getRegionByFilter({page: index, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId})?.then(resPage => {
                      if (listTemps.length === 0) {
                        listTemps = resPage?.data?.entities;
                      } else {
                        listTemps = listTemps.concat(resPage?.data?.entities);
                      }
          
                      if (listTemps.length > 0) {
                        const newOption = listTemps.map((i: any) => ({ label: i.name, value: i.id }));
                        setRegionTypeOptions(newOption);
                      }
                    });
                }
            }
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isEdit) {
            setValue('name', targetData.name);
            const categoryId = targetData.category?.id;
            if (categoryId) {
                const cateSelect: any = categoryTypeOptions.find(c => c.value === categoryId);
                setSelectedCategory(cateSelect);
            }
            setValue('categoryId', categoryId);
            setValue('sku', targetData.sku);
            setValue('unitPrice', targetData.unitPrice);
            setValue('caseCost', targetData.caseCost);
            setUpcs(targetData.upcs);
            if (targetData?.notAvailableAts) {
                const regionSelect: any = regionTypeOptions?.filter((x: any) => targetData?.notAvailableAts?.includes(x.value));
                setSelectedRegion(regionSelect);
                setValue("notAvailableAts", regionSelect?.map((x: any) => { return x.value}));
            }
            reset(targetData);
        } else {
            reset();
        }

        // eslint-disable-next-line
    }, [categoryTypeOptions]);

    const handleAddAndUpdateItem = async (payload: any) => {
        if (isEdit) {
            payload.organizationId = organizationId;
            payload.organization = {id: organizationId};
            payload.category = {id: payload.categoryId};
            payload.unitPrice = parseFloat(payload.unitPrice);
            payload.caseCost = parseFloat(payload.caseCost);
            const upcDefault = upcs?.find((x: any) => x.default === true);
            const caseQuantity = upcDefault ? parseInt(upcDefault.caseQuantity) : 0;
            payload.unitCost = caseQuantity > 0 ? payload.caseCost / caseQuantity : 0;
            payload.upcs = upcs;
            await updateItemById(targetData.id, payload);
        } else {
            payload.organizationId = organizationId;
            payload.organization = {id: organizationId};
            payload.category = {id: payload.categoryId};
            payload.unitPrice = parseFloat(payload.unitPrice);
            payload.caseCost = parseFloat(payload.caseCost);
            payload.isActive = true;
            const upcDefault = upcs?.find((x: any) => x.default === true);
            const caseQuantity = upcDefault ? parseInt(upcDefault.caseQuantity) : 0;
            payload.unitCost = caseQuantity > 0 ? payload.caseCost / caseQuantity : 0;
            payload.upcs = upcs;
            await createItem(payload);
        }
    };

    const mutation = useMutation(['create-update-item'], {mutationFn: handleAddAndUpdateItem});

    const onSubmit = (values: any, props: any) => {
        mutation.mutate(values, {
            onSuccess: () => {
                toggleHandler();
                if (currentPage !== 0) {
                    queryClient.invalidateQueries('getItems', {refetchActive: false}, {cancelRefetch: true});
                    setCurrentPage(0);
                } else queryClient.invalidateQueries('getItems', {refetchActive: true}, {cancelRefetch: true});
                const message: string = isEdit ? t('itemPage.editSuccess') : t('itemPage.saveSuccess');
                toast.success(message);
                fetchCurrentUser();
            },
            onError: error => {
                const message: string = messageErrors(error, t);
                toast.error(message);
            },
        });
    };

    const handleChangeCategory = (option: any) => {
        setSelectedCategory(option);
        setValue('categoryId', option.value);
    };

    const handleChangeRegion = async (options: any) => {
        const selectValues: any = [];
        if (options) {
            for (let index = 0; index < options.length; index++) {
                const element = options[index];
                const resChild = await getChildrenRegionByParent(element.value);
                if (resChild?.data?.length > 0) {
                    const check = selectValues?.find((x: any) => x.value === element.value);
                    if (!check) selectValues.push(element);
                    for (let index = 0; index < resChild?.data?.length; index++) {
                        const child = resChild?.data[index];
                        const selectValue = regionTypeOptions?.find((x: any) => x.value === child.id);
                        const check = selectValues?.find((x: any) => x.value === selectValue?.value);
                        if (!check) selectValues.push(selectValue);
                    }
                } else {
                    const check = selectValues?.find((x: any) => x.value === element.value);
                    if (!check) selectValues.push(element);
                }
            }
        }
        setSelectedRegion(selectValues);
        setValue('notAvailableAts', selectValues?.map((x: any) => { return x.value}));
    };

    const toggleHandler = () => {
        setOpenModal(!openModal);
        reset();
    };

    const handleAddUpc = () => {
        const check = upcs?.find((x: any) => x.upcCode === '');
        if (check) {
            toast.error(`${t('itemPage.upcs.requiredInputUpc')}`);
        } else {
            const newRow: any = {
                id: uuidv4(),
                upcCode: '',
                caseQuantity: 0,
                default: false
            };
            const newData: any = concat(upcs, newRow);
            setUpcs(newData);
        }
    };

    const handleDeleteRow = (id: string) => {
        const newData = upcs?.filter((d: any) => d.id !== id);
        setUpcs(newData);
    }

    const handleChangeContentOverview = async (id: string, value: any, field: any) => {
      const listContentClone = cloneDeep(upcs);
      const findContent: any = listContentClone?.find((item: any) => item.id === id);
      if (field === 'caseQuantity') {
        findContent[field] = parseInt(value);
      }
      if (field === 'default') {
          if (value === true) {
            findContent[field] = value;
            const listContentChecked: any = listContentClone?.filter((item: any) => item.id !== id);
            listContentChecked?.forEach((x: any) => {
              x.default = false;
            });
          } else {
            findContent[field] = value;
          }
      } else {
        findContent[field] = value;
      }
      setUpcs(listContentClone);
    };

    return (
        <div ref={rootRef} className="item-modal-container">
            <Modal
                show={openModal}
                size="4xl"
                className="item-modal"
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={true}
                theme={themModal}
            >
                <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                <Modal.Body theme={{base: 'flex-12 pb-4 pt-3 px-12'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mt-2 relative flex md:flex-row flex-col">
                            <InputText hookForm={hookForm} placeholder={`${t('itemPage.modal.modalItemName')}`}
                                       name="name" id="name" className="md:mr-4 mb-2 md:mb-0" isHolderLabel={true}/>
                            <MultiSelect
                                className="mb-2 md:mb-0"
                                onChange={handleChangeRegion}
                                options={regionTypeOptions}
                                value={selectedRegion}
                                hookForm={hookForm}
                                name="regionId"
                                isMulti={true}
                                noOptionsMessage={() => t('No options')}
                                closeMenuOnSelect
                                isHolderLabel={true}
                                placeholder={`${t('itemPage.modal.modalNotAvailableAts')}`}
                            />
                        </div>
                        <div className="mt-4 md:mt-2 relative flex md:flex-row flex-col">
                            <MultiSelect
                                className="md:mr-4 mb-2 md:mb-0"
                                onChange={handleChangeCategory}
                                options={categoryTypeOptions}
                                value={selectedCategory}
                                hookForm={hookForm}
                                name="categoryId"
                                noOptionsMessage={() => t('No options')}
                                closeMenuOnSelect
                                isHolderLabel={true}
                                placeholder={`${t('itemPage.modal.modalCategory')}`}
                            />
                            <InputText hookForm={hookForm} placeholder={`${t('itemPage.modal.modalSku')}`} name="sku"
                                       id="sku" className="mb-2 md:mb-0" isHolderLabel={true}/>
                        </div>
                        <div className="mt-4 md:mt-2 relative flex md:flex-row flex-col">
                            <InputText
                                hookForm={hookForm}
                                placeholder={`${t('itemPage.modal.modalSalePrice')}`}
                                type="number"
                                step="0.01"
                                name="unitPrice"
                                id="unitPrice"
                                isHolderLabel={true}
                                className="md:mr-4 mb-2 md:mb-0"
                            />
                            <InputText hookForm={hookForm} placeholder={`${t('itemPage.modal.modalCaseCost')}`}
                                       className="mb-2 md:mb-0" type="number" step="0.01" name="caseCost" id="caseCost"
                                       isHolderLabel={true}/>
                        </div>
                        {/* Add UPCs */}
                            <GroupButtonWithIcons
                                className="add-UPCs"
                                buttons={[
                                    {
                                        type: 'button',
                                        text: `${t('itemPage.upcs.add')}`,
                                        classType: 'white',
                                        icon: <HiPlus size={"18px"}/>,
                                        action: () => handleAddUpc(),
                                    }
                                ]}
                            />
                        {/* Table */}
                        {upcs?.length > 0 && (
                            <Table className="upcs-table">
                                <Table.Head className="text-xs text-gray-900 text-xs border-b-2 border-gray-100">
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium px-2 py-1 flex-1"
                                    >
                                        {t('itemPage.upcs.upcCode')}
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium px-2 py-1"
                                    >
                                        {t('itemPage.upcs.quantity')}
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="cursor-pointer bg-white font-medium px-2 py-1"
                                    >
                                        {t('itemPage.upcs.default')}
                                    </Table.HeadCell>

                                </Table.Head>
                                <Table.Body>
                                    {upcs.map((item: any, index: number) => (
                                        <>
                                            <Table.Row key={`row-${index + 1}`}
                                                       className="bg-white cursor-pointer text-sm hover:bg-gray-50 border-b border-gray-100">
                                                <Table.Cell className="font-semibold py-[8px] px-2 custom-input">
                                                    <TextInput
                                                        type="text"
                                                        id={`upc-${index}`}
                                                        name={`upc-${index}`}
                                                        value={item?.upcCode}
                                                        onChange={(e: any) => {
                                                            handleChangeContentOverview(item?.id, e.target.value, 'upcCode');
                                                        }}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell
                                                    className="font-semibold py-[8px] w-[200px] px-2 custom-input">
                                                    <TextInput
                                                        type="number"
                                                        id={`caseQuantity-${index}`}
                                                        name={`caseQuantity-${index}`}
                                                        value={item?.caseQuantity}
                                                        onChange={(e: any) => {
                                                            handleChangeContentOverview(item?.id, e.target.value, 'caseQuantity');
                                                        }}
                                                    />
                                                </Table.Cell>

                                                <Table.Cell className="py-[8px] px-2 w-[100px] ">
                                                        <Radio
                                                            id={`default-${index}`}
                                                            name={`default-${index}`}
                                                            value={item?.default}
                                                            checked={item?.default}
                                                            onChange={(e: any) => {
                                                                handleChangeContentOverview(item?.id, e.target.checked, 'default');
                                                            }}
                                                        />
                                                </Table.Cell>
                                                <Table.Cell className="py-[8px] px-2">
                                                    <GroupButtonWithIcons
                                                        className="items-center justify-center"
                                                        buttons={[
                                                            {
                                                                type: 'button',
                                                                text: '',
                                                                classType: 'white',
                                                                icon: <HiOutlineTrash size={"20px"}/>,
                                                                action: () => handleDeleteRow(item?.id)
                                                            },
                                                            {
                                                                type: 'button',
                                                                text: '',
                                                                classType: 'white',
                                                                icon: <HiPlus size={"20px"}/>,
                                                                action: () => handleAddUpc()
                                                            },
                                                        ]}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                        <GroupButton
                            className="items-center justify-center pt-5 mb-4"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.cancel'),
                                    classType: 'white',
                                    action: () => toggleHandler(),
                                },
                                {
                                    type: 'submit',
                                    text: t('modal.save'),
                                    classType: 'blue',
                                    isLoading: mutation.isLoading,
                                },
                            ]}
                        />
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default ItemModal;
