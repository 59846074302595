import {permissionKeys, poStatusOptions, searchMaxLength, sortByTypeType, defaultpageCount} from 'utils/constants';
import {HiOutlineCalendar, HiOutlineSearch, HiOutlineX} from 'react-icons/hi';
import {useTranslation} from 'react-i18next';
import {isEmpty} from 'lodash';
import MultiSelect from 'components/select/index';
import {useEffect, useRef, useState} from 'react';
import {getRegionByFilter} from 'api/regionApi';
import {useSelector} from 'react-redux';
import {orgSelector, userSelector} from 'redux/selectors';
import {getLocationByFilter} from 'api/locationApi';
import Buttons from "../button/Buttons";
import Flatpickr from 'react-flatpickr';
import 'rsuite/dist/rsuite.min.css';
import './actionTable.scss';
import SelectMulti from 'components/select/multiSelect';
import { checkOrgAdmin, checkPermission, getUserOrg } from 'utils/utils';

type buttonProps = {
    buttonName: string,
    buttonHidden: boolean,
    buttonAction: Function,
    className?: string,
};
const ActionTableWithFilter = (props: any) => {
    const {
        placeholderSearch,
        buttonName,
        handleAddClick,
        exportHandler,
        buttons,
        handleSearch,
        buttonExports,
        hiddenSearch = false,
        location,
        setLocation,
        region,
        setRegion,
        fDate,
        setFDate,
        tDate,
        setTDate,
        statuss,
        setStatuss,
        isUnread,
        setIsUnread,
        type = 'requested'
    } = props;
    const [t] = useTranslation();
    const {PLATFORM_ADMIN} = permissionKeys;
    const [locationTypeOptions, setLocationTypeOptions] = useState([{label: '...', value: ''}]);
    const [readTypeOptions, setReadTypeOptions] = useState([{label: 'All', value: 'All'}, {label: 'Read', value: 'Read'}, {label: 'Unread', value: 'Unread'}]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedRead, setSelectedRead] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [regionTypeOptions, setRegionTypeOptions] = useState([{label: '...', value: ''}]);
    const [dateFilter, setDateFilter] = useState<any>([]);
    const {organizationId} = useSelector(orgSelector);
    const flatpickrRef = useRef<Flatpickr | null>(null);
    const [statusTypeOptions, setStatusTypeOptions] = useState(poStatusOptions);
    const {userInfo} = useSelector(userSelector);
    const isAdmin = checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId);
    const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);

    const pageSize = 100;

    const getRegions = () => {
        const userOrg = userInfo?.organizations?.find((o: any) => o.id === organizationId);
        const orgRegions = userOrg?.regions;
        let regionIds = '';
        if (orgRegions) {
            for (let index = 0; index < orgRegions.length; index++) {
                const region = orgRegions[index];
                if (regionIds === '') {
                    regionIds = region.id;
                } else {
                    regionIds = `${regionIds},${region.id}`;
                }
            }
        }
        return regionIds;
    }

    useEffect(() => {
        getRegionByFilter({page: 0, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId})?.then(res => {
            const totalCount = res?.data?.totalEntities;
            if (totalCount === 0) {
                setRegionTypeOptions([]);
            } else {
                const pageBreak = Math.ceil(totalCount / pageSize);
                let listTemps: any = [];
                for (let index = 0; index < pageBreak; index++) {
                    // eslint-disable-next-line
                    getRegionByFilter({page: index, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId})?.then(resPage => {
                      if (listTemps.length === 0) {
                        listTemps = resPage?.data?.entities;
                      } else {
                        listTemps = listTemps.concat(resPage?.data?.entities);
                      }
          
                      if (listTemps.length > 0) {
                        const newOption = listTemps.map((i: any) => ({ label: i.name, value: i.id }));
                        setRegionTypeOptions(newOption);
                      }
                    });
                }
            }
          });

        getLocationByFilter({ page: 0, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId, regionIds: isOrgAdmin === true ? '' : getRegions(), isActive: true })?.then(res => {
            const totalCount = res?.data?.totalEntities;
            if (totalCount === 0) {
                setLocationTypeOptions([]);
            } else {
                const pageBreak = Math.ceil(totalCount / pageSize);
                let listTemps: any = [];
                for (let index = 0; index < pageBreak; index++) {
                    // eslint-disable-next-line
                    getLocationByFilter({ page: index, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId, regionIds: isOrgAdmin === true ? '' : getRegions(), isActive: true })?.then(resPage => {
                        if (listTemps.length === 0) {
                            listTemps = resPage?.data?.entities;
                        } else {
                            listTemps = listTemps.concat(resPage?.data?.entities);
                        }
      
                        if (listTemps.length > 0) {
                            const  newOption = listTemps.map((i: any)=> ({label: i.name, value: i.id}));
                            setLocationTypeOptions(newOption);
                        }
                      });
                }
            }
        });

        if (flatpickrRef.current) {
            const flatpickr = flatpickrRef.current;
            if (flatpickr) {
                flatpickr.flatpickr.set('mode', 'range');
            }
        }

        // eslint-disable-next-line
    }, []);

    const handleoOnKeyUp = (e: any) => {
        const {key, target} = e;
        if (key === 'Enter') handleSearch(target.value);
    };

    const handleoOnChange = (e: any) => {
        if (isEmpty(e.target.value)) handleSearch(e.target.value);
    };

    const handleChangeLocation = (a: any) => {
        if (a) {
            setSelectedLocation(a);
            setLocation(a.value);
        } else {
            setSelectedLocation(null);
            setLocation('');
        }
    }

    const handleChangeRead = (a: any) => {
        if (a) {
            setSelectedRead(a);
            switch (a.value) {
                case "Unread":
                    setIsUnread(true);
                    break;
                case "Read":
                    setIsUnread(false);
                    break;
                default:
                    setIsUnread(undefined);
                    break;
            }
        } else {
            setSelectedRead(null);
            setIsUnread(undefined);
        }
    }

    const handleChangeRegion = (option: any) => {
        if (option) {
            setSelectedRegion(option);
            setRegion(option.value);
        } else {
            setSelectedRegion(null);
            setRegion('');
        }
    };

    const handleChangeDate = (option: any) => {
        if (option) {
            if (option[0]) {
                setFDate((option[0] as Date).toLocaleDateString());
            }

            if (option[1]) {
                setTDate((option[1] as Date).toLocaleDateString());
            }
            setDateFilter(option);
        } else {
            setFDate('');
            setTDate('');
            setDateFilter([]);
        }
    };

    const handleClearSelection = () => {
        if (flatpickrRef.current) {
            flatpickrRef.current.flatpickr.clear();
            setFDate('');
            setTDate('');
        }
    };

    return (
        <div className="flex pb-3 justify-between at-requestedPOs">
            <div className="desktop">
                <div
                    className="flex items-center text-search border border-gray-200 mr-[15px] action-search rounded-sm pl-2 h-[36px]">
                    {!hiddenSearch && (
                        <span className="pl-1"><HiOutlineSearch strokeWidth={3} className="w-4 h-4"/></span>
                    )}
                    <input
                        className="px-2 text-sm border-0 focus:outline-none focus:drop-shadow-none w-full bg-transparent"
                        type="search"
                        name="search"
                        hidden={hiddenSearch}
                        maxLength={searchMaxLength}
                        placeholder={placeholderSearch}
                        onChange={e => handleoOnChange(e)}
                        onKeyUp={e => handleoOnKeyUp(e)}
                    />
                </div>
                { (type === 'requested' || type === 'notificationList') && (
                    <>
                        <MultiSelect
                            onChange={(e: any) => {
                                handleChangeRegion(e);
                            }}
                            options={regionTypeOptions}
                            value={selectedRegion}
                            id={`region-filter`}
                            name={`region-filter`}
                            noOptionsMessage={() => t('No options')}
                            placeholder={t('orderPage.placeholderRegion')}
                            closeMenuOnSelect
                            isHolderLabel={false}
                        />
                        <MultiSelect
                            onChange={(e: any) => {
                                handleChangeLocation(e);
                            }}
                            options={locationTypeOptions}
                            value={selectedLocation}
                            id={`location-filter`}
                            name={`location-filter`}
                            noOptionsMessage={() => t('No options')}
                            placeholder={t('orderPage.placeholderLocation')}
                            closeMenuOnSelect
                            isHolderLabel={false}
                        />
                    </>
                )}
                <div className="custom-date-range">
                    <Flatpickr
                        ref={flatpickrRef}
                        value={dateFilter}
                        onChange={(e: any) => handleChangeDate(e)}
                        options={
                            {
                                mode: 'range',
                                dateFormat: 'M-d-Y'
                            }
                        }
                        placeholder="Date Range"
                    />
                    {fDate !== '' && tDate !== '' ? <button onClick={handleClearSelection}><HiOutlineX/></button> :
                        <HiOutlineCalendar/>}
                </div>
                { (type === 'requested' || type === 'poList') && (
                    <SelectMulti
                        data={statusTypeOptions}
                        selectedData={statuss}
                        setSelectedData={setStatuss}
                        placeholder={t('itemPage.status')}
                    />
                )}
                { type === 'notificationList' && (
                    <MultiSelect
                        onChange={(e: any) => {
                            handleChangeRead(e);
                        }}
                        options={readTypeOptions}
                        value={selectedRead}
                        id={`read-filter`}
                        name={`read-filter`}
                        noOptionsMessage={() => t('No options')}
                        placeholder={t('notificationPage.placeholderRead')}
                        closeMenuOnSelect
                        isHolderLabel={false}
                    />
                )}
            </div>
            <div className="flex justify-end gap-3 action-button">
            {buttons
                    ? buttons.map((button: buttonProps) => {
                        return (
                            <>
                                {button.buttonHidden && (

                                    <Buttons className={button.className || 'text-white bg-secondary'} text={button.buttonName}
                                             onClick={() => button.buttonAction()}/>
                                )}
                            </>
                        );
                    })
                    : handleAddClick && (
                    <Buttons className="text-white bg-secondary" text={buttonName}
                             onClick={() => handleAddClick()}/>
                )}
                {buttonExports && (
                    buttonExports.map((buttonExport: buttonProps) => {
                        return (

                            <Buttons className="text-secondary border border-secondary"
                                     text={buttonExport.buttonName} onClick={() => buttonExport.buttonAction()}/>
                        )
                    })
                )}
            </div>
        </div>
    );
};
export default ActionTableWithFilter;
