import {useAuth0} from '@auth0/auth0-react';
import {Dropdown} from 'flowbite-react';
import {useDispatch, useSelector} from 'react-redux';
import {orgSelector, userSelector} from 'redux/selectors';
import {checkPermission, getNavbarUrl, checkOrgAdmin} from 'utils/utils';
import {useNavigate} from 'react-router-dom';
import {pageType, permissionKeys, permissionsOrg, permissionsSite, roleName, routePaths} from 'utils/constants';
import useUser from 'hooks/useUser';
import {useTranslation} from 'react-i18next';
import {useEffect, useRef, useState} from 'react';
import './userProfile.scss';
import {size} from 'lodash';
import {userAction} from '../redux/actions';
import Avatar from './GroupAvatar/Avatar';
import {HiArrowNarrowRight, HiQrcode} from "react-icons/hi";

const UserProfile = () => {
    const isUseSubscription = process.env.REACT_APP_IS_USE_SUBSCRIPTION === 'true';
    const {WRITE_ORG, PLATFORM_ADMIN} = permissionKeys;
    const dispatch = useDispatch();
    const {user} = useAuth0();
    const ref = useRef<any>();
    const {organizationId} = useSelector(orgSelector);
    const navigate = useNavigate();
    const [t] = useTranslation();
    const {userInfo} = useSelector(userSelector);
    const {userRole, firstName, lastName} = userInfo;
    const {handleLogout} = useUser();
    const [isShowDropDown, setIsShowDropDown] = useState(false);
    const isAdmin = checkPermission(userInfo, pageType.SITE, [PLATFORM_ADMIN], organizationId);
    const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsShowDropDown(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [isShowDropDown]);

    const handleOpenModalProfile = () => {
        const isOpenModal: any = true;
        dispatch(userAction.setOpenProfileModal(isOpenModal));
    };
    const handleOpenModalScanner = () => {
        const isOpenScannerModal: any = true;
        dispatch(userAction.setOpenScannerModal(isOpenScannerModal));
    };
    return (
        <div className="user-profile">
            <div className={`relative user-dropdown flex items-center cursor-pointer`} ref={ref}
                 onClick={() => setIsShowDropDown(!isShowDropDown)} data-testid={`test-user-profile`}>
                <div className="flex justify-between items-center w-full">
                    <Avatar item={userInfo}/>
                </div>
                <div className={`${!isShowDropDown && 'hidden'} dropdown-item text-sm`}>
                    {/* Avatar */}
                    <div className="flex p-3">
                        <Avatar item={userInfo}/>
                        <div className="ml-3 flex flex-col justify-center">
                            <span
                                className="block capitalize text-black font-medium">{`${firstName} ${lastName}`}</span>
                            <span className="block truncate text-xs text-gray-500 text-right">{user?.email}</span>
                        </div>
                    </div>
                  <Dropdown.Divider/>

                  {/* Scanner Login */}
                  <div className="scanner-login" onClick={handleOpenModalScanner}>
                      <HiQrcode className='w-5 h-5 mr-[5px]'/>

                    {t('navbar.scannerLogin')}
                  </div>
                  <Dropdown.Divider/>

                  {/**/}
                    <div
                        className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer hover:bg-gray-100 cursor-pointer"
                        onClick={handleOpenModalProfile}>
                        {t('navbar.profile')}
                    </div>
                    {isUseSubscription && (
                        <div className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer"
                             onClick={() => navigate(routePaths.MY_SUBSCRIPTIONS)}>
                            {t('navbar.mySubscriptions')}
                        </div>
                    )}
                    {isOrgAdmin && size(userInfo.organizations) > 0 && (
                        <div
                            className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer"
                            onClick={() => navigate(getNavbarUrl(userInfo, pageType.ORGANIZATION, organizationId))}
                        >
                            {t('navbar.organizationSettings')}
                        </div>
                    )}
                    {checkPermission(userInfo, pageType.SITE, permissionsSite, organizationId) && (
                        <div
                            className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer"
                            onClick={() => navigate(getNavbarUrl(userInfo, pageType.SITE, organizationId))}
                        >
                            {t('navbar.admin')}
                        </div>
                    )}
                    {userRole === roleName.INTEGRATOR && (
                        <>
                            <Dropdown.Divider/>
                            <div className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer"
                                 onClick={() => navigate(routePaths.MY_TOKENS)}>
                                {t('navbar.integrator')}
                            </div>
                        </>
                    )}
                    <Dropdown.Divider/>

                    <div className="px-4 py-2 text-gray-900 hover:bg-gray-100 cursor-pointer"
                         onClick={() => handleLogout()}>
                        <span className="text-red-500">{t('logout')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UserProfile;
