import dayjs from 'dayjs';
import { Slide, toast, TypeOptions } from 'react-toastify';
import { concat, find, flattenDeep, intersection, isArray, isEmpty, map, uniq } from 'lodash';
import * as XLSX from 'xlsx';
import { ORG_COOKIE_KEY, pageType, permissionKeys, routePaths } from './constants';
import { getCookie } from './cookie';
import { userType } from './proptypes';
import moment from 'moment';
import copy from 'copy-to-clipboard';

const formatDate = (date: string) => {
  const datejs = dayjs(date);
  const $d = datejs.toDate();
  const $D = datejs.date();
  const $M = datejs.month();
  const $y = datejs.year();

  const dateTime = $d.toString().split(' ')[4];

  const splitTime = dateTime.split(':');

  return `${$D > 9 ? $D : '0' + $D}/${$M + 1 > 9 ? $M + 1 : '0' + ($M + 1)}/${$y} ${
    parseInt(splitTime[0]) > 12 ? parseInt(splitTime[0]) - 12 : splitTime[0]
  }:${splitTime[1]}${parseInt(splitTime[0]) > 12 ? 'pm' : 'am'}`;
};

const formatDateNotTime = (date: string) => {
  if (date === null || date === '') {
    return '';
  }
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];
  const datejs = dayjs(date);
  const $D = datejs.date();
  const $M = datejs.month();
  const $y = datejs.year();

  return `${monthNames[$M]} ${$D > 9 ? $D : '0' + $D} ,${$y}`;
};

const formatDateWithTime = (date: string) => {
  if (date === null || date === '') {
    return '';
  }
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];
  const datejs = dayjs(date);
  const $d = datejs.toDate();
  const $D = datejs.date();
  const $M = datejs.month();
  const $y = datejs.year();

  const dateTime = $d.toString().split(' ')[4];

  const splitTime = dateTime.split(':');

  return `${monthNames[$M]} ${$D > 9 ? $D : '0' + $D} ,${$y} ${
    parseInt(splitTime[0]) > 12 ? parseInt(splitTime[0]) - 12 : splitTime[0]
  }:${splitTime[1]}:${splitTime[2]}${parseInt(splitTime[0]) > 12 ? ' PM' : ' AM'}`;
};

const formatMoney = (amount: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const formattedMoney = formatter.format(amount);

  return formattedMoney;
}

const checkLowerUpper = (value: string) => {
  return /(?=.*[A-Z])(?=.*[a-z]).*$/.test(value);
};

const checkNumberDymbol = (value: string) => {
  return /(?=.*\d)(?=.*\W+).*$/.test(value);
};

const checkLeast8Char = (value: string) => {
  return value.length >= 8;
};

const checkNoSpaces = (value: string) => {
  return /^\S+$/.test(value);
};

const validatiePassword = (value: string) => {
  return checkLowerUpper(value) && checkNumberDymbol(value) && checkLeast8Char(value) && checkNoSpaces(value);
};

const showToast = (key: TypeOptions, message: any) => {
  if (key === 'success' || key === 'error' || key === 'warning') {
    return toast[key](message, { transition: Slide, autoClose: 1500, icon: false });
  }
};

const messageErrors = (error: any, translation: any) => {
  if ([500, 404].includes(error?.response?.status)) {
    return translation('generalErrors');
  } else {
    const message = error?.response?.data?.errors[0]?.detail;
    return (isArray(message) ? message[0] : message) || translation('generalErrors');
  }
};

const exportToFile = (exportedData: any, fileName: string) => {
  const wb = XLSX.utils.json_to_sheet(exportedData);
  const wbout = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wbout, wb);
  XLSX.writeFile(wbout, fileName);
};

const checkPermission = (user: any, type: any, permNeed: any = [], organizationId = getCookie(ORG_COOKIE_KEY), platformAdmin?: any): any => {
  const permissionsSite = uniq(flattenDeep(map(user?.roles, (r: any) => r?.permissions)));
  let permissionsOrg: any = [];
  const roleOrg = find(user?.organizations, o => o.id === organizationId)?.role;
  if (roleOrg) {
    permissionsOrg = roleOrg.permissions;
  } else {
    const rolesOrg = find(user?.organizations, o => o.id === organizationId)?.roles;
    permissionsOrg = uniq(flattenDeep(map(rolesOrg, (r: any) => r?.permissions)));
  }
  if (permissionsSite.includes(permissionKeys.PLATFORM_ADMIN)) return true;
  if (platformAdmin) return false;
  let permOfUser = concat(permissionsSite, permissionsOrg);
  if (type === pageType.ORGANIZATION || type === pageType.LOCATION) permOfUser = permissionsOrg;
  if (type === pageType.SITE) permOfUser = permissionsSite;
  return permOfUser && (isEmpty(permNeed) || !isEmpty(intersection(permOfUser, permNeed)));
};

const checkOrgAdmin = (user: any, organizationId = getCookie(ORG_COOKIE_KEY), platformAdmin?: any): any => {
  if (platformAdmin) return true
  
  const org = user.organizations?.find((o: any) => o.id === organizationId);
  if (org) {
    const rolesOrg = org?.roles;
    const orgAdmin = rolesOrg?.find((a: any) => a.displayName === 'Organization Admin');
    if (orgAdmin) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const checkUseRegionAndLocation = (user: any, organizationId = getCookie(ORG_COOKIE_KEY), platformAdmin?: any): any => {
  if (platformAdmin) return true
  
  const org = user.organizations?.find((o: any) => o.id === organizationId);
  if (org) {
    const locationUserOrg = org?.location;
    const regionsUserOrg = org?.regions;
    if (locationUserOrg || regionsUserOrg) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const getNavbarUrl = (user: any, type: string, organizationId = getCookie(ORG_COOKIE_KEY)) => {
  const permissionsSite = uniq(flattenDeep(map(user?.roles, (r: any) => r?.permissions)));
  const { READ_USER, WRITE_USER, PLATFORM_ADMIN, READ_ROLE, WRITE_ROLE, READ_ORG, WRITE_ORG, READ_AUTHEN, WRITE_AUTHEN } = permissionKeys;
  if (type === pageType.SITE) {
    if (!isEmpty(intersection(permissionsSite, [READ_USER, WRITE_USER, PLATFORM_ADMIN]))) return routePaths.ADMIN_USERS_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_ROLE, WRITE_ROLE]))) return routePaths.ROLES_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_ORG, WRITE_ORG]))) return routePaths.ORGANIZATION_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_AUTHEN, WRITE_AUTHEN]))) return routePaths.MY_TOKENS;
  } else {
    if (type === pageType.ORGANIZATION) {
      let permissionsOrg: any = [];
      const roleOrg = find(user?.organizations, o => o.id === organizationId)?.role;
      if (roleOrg) {
        permissionsOrg = roleOrg.permissions;
      } else {
        const rolesOrg = find(user?.organizations, o => o.id === organizationId)?.roles;
        permissionsOrg = uniq(flattenDeep(map(rolesOrg, (r: any) => r?.permissions)));
      }
      if (!isEmpty(intersection(permissionsSite, [PLATFORM_ADMIN]))) return routePaths.ORG_OVERVIEW_PAGE;
      if (!isEmpty(intersection(permissionsOrg, [READ_USER, WRITE_USER]))) return routePaths.ORG_OVERVIEW_PAGE;
    } else {
      const permissionsOrg = find(user?.organizations, o => o.id === organizationId)?.role?.permissions || [];
      if (!isEmpty(intersection(permissionsSite, [PLATFORM_ADMIN]))) return routePaths.DASHBOARD_PAGE;
      if (!isEmpty(intersection(permissionsOrg, [READ_USER, WRITE_USER]))) return routePaths.DASHBOARD_PAGE;
    }
  }
  return routePaths.DASHBOARD_PAGE;
};

const getAvatarColor = (item: userType) => {
  const colors = ['#FACA15', '#31C48D', '#16BDCA', '#6875F5', '#9061F9', '#E74694', '#FF5A1F', '#F05252'];
  const sumOfDateNumbers = moment(item?.created)
    .utc()
    .format('MM DD YYYY hh mm ss')
    .split('')
    .map(Number)
    .reduce((a, b) => {
      return a + b;
    }, 0);
  const pick = sumOfDateNumbers % colors.length;
  return colors[pick];
};

const getUserOrg = (user: any, organizationId: string) => {
  return user.organizations?.find((o: any) => o.id === organizationId);
}

const copyToClipboard = (value: string, notification: string) => {
  copy(value);
  toast.success(notification);
};

export {
  formatDate,
  formatDateNotTime,
  formatDateWithTime,
  formatMoney,
  showToast,
  checkLowerUpper,
  checkNumberDymbol,
  checkLeast8Char,
  checkNoSpaces,
  validatiePassword,
  messageErrors,
  exportToFile,
  checkPermission,
  checkOrgAdmin,
  checkUseRegionAndLocation,
  getNavbarUrl,
  getAvatarColor,
  getUserOrg,
  copyToClipboard,
};
