/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import {HiOutlineCalendar, HiOutlineX} from 'react-icons/hi';
import CustomModalHeader from 'components/modal/customModalHeader';
import {Modal} from 'flowbite-react';
import {useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './purchaseOrderModal.scss';
import {locationSelector, orgSelector, userSelector} from 'redux/selectors';
import {useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import MultiSelect from 'components/select/index'
import GroupButton from 'components/button/groupButton';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'; // Import a Flatpickr theme
import {exportPurchaseOrderFormOrg} from 'api/purchaseOrderApi';
import {toast} from 'react-toastify';
import { checkOrgAdmin, checkPermission, getUserOrg } from 'utils/utils';
import { pageType, permissionKeys, poStatusExportOptions, defaultpageCount, sortByTypeType } from 'utils/constants';
import { getLocationByFilter } from 'api/locationApi';

type exportOrderModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const ExportOrderModal = (props: exportOrderModalProps) => {
    const {
        openModal,
        setOpenModal,
        headerTitle,
        currentPage,
        queryClient,
        setCurrentPage
    } = props;
    const [t] = useTranslation();
    const { userInfo } = useSelector(userSelector);
    const {organizationId} = useSelector(orgSelector);
    const {locationId} = useSelector(locationSelector);
    const rootRef = useRef(null);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [statusTypeOptions, setStatusTypeOptions] = useState(poStatusExportOptions);
    const [selectedType, setSelectedType] = useState<any>({label: 'This Location', value: "This"});
    const [typeOptions, setTypeOptions] = useState([
            {label: 'This Location', value: "This"},
            {label: 'All My Locations', value: "All"}
        ]);
    const flatpickrRef = useRef<Flatpickr | null>(null);
    const [dateFilter, setDateFilter] = useState<any>([]);
    const [fDate, setFDate] = useState('');
    const [tDate, setTDate] = useState('');
    const [poStatus, setPoStatus] = useState([]);
    const {WRITE_LOCATION, PLATFORM_ADMIN} = permissionKeys;
    const isAdmin = checkPermission(userInfo, pageType.LOCATION, [PLATFORM_ADMIN], organizationId);
    const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);
  

  const getRegions = () => {
    const userOrg = userInfo?.organizations?.find((o: any) => o.id === organizationId);
    const orgRegions = userOrg?.regions;
    let regionIds = '';
    if (orgRegions) {
        for (let index = 0; index < orgRegions.length; index++) {
            const region = orgRegions[index];
            if (regionIds === '') {
                regionIds = region.id;
            } else {
                regionIds = `${regionIds},${region.id}`;
            }
        }
    }
    return regionIds;
}

    const hookForm = useForm({
        mode: "onChange",
    });

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        reset,
        getValues,
    } = hookForm;

    const toggleHandler = () => {
        setOpenModal(!openModal);
    };

    const handleChangeStatus = (options: any) => {
        setSelectedStatus(options);
        if (options?.length > 0) {
            const statuss: any = [];
            for (let index = 0; index < options?.length; index++) {
                const element = options[index];
                statuss.push(element?.value);
            }
            setPoStatus(statuss);
        } else {
            setPoStatus([]);
        }
    };

    const handleChangeType = (option: any) => {
        setSelectedType(option);
    };

    const handleChangeDate = (option: any) => {
        if (option) {
            if (option[1]) {
                setFDate((option[0] as Date).toLocaleDateString());
                setTDate((option[1] as Date).toLocaleDateString());
                setDateFilter(option);
            }
        } else {
            setFDate('');
            setTDate('');
            setDateFilter([]);
        }
    };

    const handleClearSelection = () => {
        if (flatpickrRef.current) {
            flatpickrRef.current.flatpickr.clear();
            setFDate('');
            setTDate('');
        }
    };

    const handleExport = async () => {
        setFDate(fDate);
        setTDate(tDate);
        setPoStatus(poStatus);
        setSelectedType(selectedType);
        let statuss = '';
        for (let index = 0; index < poStatus?.length; index++) {
            const status = poStatus[index];
            if (statuss === '') {
                statuss = status;
            } else {
                statuss = `${statuss},${status}`;
            }
        }
        if (selectedType?.value === "This") {
            exportPurchaseOrderFormOrg(organizationId, {fDate, tDate, statuss, locationId}).then(res => {
                if (res.data || res.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `purchase-order-export.xlsx`);
                    document.body.appendChild(link)
                    link.click();
    
                    // Remove dom after finish
                    document.body.removeChild(link);
                }
            }).catch(err => {
                toast.error(`${t('uploadFile.noItemExport')}`);
            });
        } else {
            let ids = '';
            const resCount = await getLocationByFilter({ page: 0, limit: 5, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId, regionIds: isOrgAdmin === true ? '' : getRegions(), isActive: true });
            const totalCount  = resCount?.data?.totalEntities;
            const pageBreak = Math.ceil(totalCount / defaultpageCount);
            let listTemps: any = [];
            for (let index = 0; index < pageBreak; index++) {
                const resPage = await getLocationByFilter({ page: index, limit: defaultpageCount, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId, regionIds: isOrgAdmin === true ? '' : getRegions(), isActive: true });
                if (listTemps.length === 0) {
                    listTemps = resPage?.data?.entities;
                } else {
                    listTemps = listTemps.concat(resPage?.data?.entities);
                }
            }

            if (listTemps) {
                for (let index = 0; index < listTemps?.length; index++) {
                    const element = listTemps[index];
                    if (ids === '') {
                        ids = element.id;
                    } else {
                        ids = `${ids},${element.id}`;
                    }
                }
            }
            exportPurchaseOrderFormOrg(organizationId, {fDate, tDate, statuss, locationIds: ids}).then(res => {
                if (res.data || res.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `purchase-order-export.xlsx`);
                    document.body.appendChild(link)
                    link.click();
    
                    // Remove dom after finish
                    document.body.removeChild(link);
                }
            }).catch(err => {
                toast.error(`${t('uploadFile.noItemExport')}`);
            });
        }
    }

    return (
        <div ref={rootRef} className="order-modal-container">
            <Modal
                show={openModal}
                size="2xl"
                className="order-modal"
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={false}
                theme={themModal}
            >
                <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                <Modal.Body theme={{base: 'flex-12 pb-4 pt-3 px-12'}}>
                    <form>
                        <div className="custom-date-range-modal date-range48 mt-4 relative">
                            <Flatpickr
                                ref={flatpickrRef}
                                value={dateFilter}
                                onChange={(e: any) => handleChangeDate(e)}
                                options={
                                    {
                                        mode: 'range',
                                        dateFormat: 'M-d-Y'
                                    }
                                }
                                placeholder="Select Date"
                            />
                            {fDate !== '' && tDate !== '' ?
                                <button onClick={handleClearSelection}><HiOutlineX/></button> : <HiOutlineCalendar/>}
                        </div>
                        <div className="mt-4 relative">
                            <MultiSelect
                                onChange={(option: any) => handleChangeStatus(option)}
                                options={statusTypeOptions}
                                value={selectedStatus}
                                isMulti={true}
                                hookForm={hookForm}
                                name="status"
                                noOptionsMessage={() => t('No options')}
                                closeMenuOnSelect
                                isHolderLabel={true}
                                placeholder={`${t('itemPage.status')}`}
                            />
                        </div>
                        <div className="mt-4 relative">
                            <MultiSelect
                                onChange={(option: any) => handleChangeType(option)}
                                options={typeOptions}
                                value={selectedType}
                                isMulti={false}
                                hookForm={hookForm}
                                name="type"
                                noOptionsMessage={() => t('No options')}
                                closeMenuOnSelect
                                isHolderLabel={true}
                                placeholder={`${t('orderPage.location')}`}
                            />
                        </div>
                        <GroupButton
                            className="items-center justify-center pt-5 mb-4"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.cancel'),
                                    classType: 'white',
                                    action: () => toggleHandler(),
                                },
                                {
                                    type: 'button',
                                    text: t('modal.export'),
                                    classType: 'blue',
                                    isLoading: false,
                                    action: () => handleExport(),
                                },
                            ]}
                        />
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default ExportOrderModal;
