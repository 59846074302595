/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import useUser from 'hooks/useUser';
import {Label, Modal, Table, TextInput, Tooltip} from 'flowbite-react';
import {useEffect, useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './stockModal.scss';
import {locationSelector, orgSelector, userSelector} from 'redux/selectors';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import {updatePurchaseOrderById} from 'api/purchaseOrderApi';
import {cloneDeep} from 'lodash';
import {getItemsByFilter} from 'api/itemApi';
import {sortByTypeType, defaultpageCount} from 'utils/constants';
import ReconcileReviewModal from './reconcileReviewModal';
import SpinnerComponent from 'components/spinner';

type receivedShipmentModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    openParentModal: boolean,
    setOpenParentModal: Function,
    headerTitle?: string,
    targetData?: any,
    purchaseOrder?: any,
    purchaseOrderItems?: any,
    isEdit: boolean,
    isImportShipment: boolean,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const ReceivedShipmentModal = (props: receivedShipmentModalProps) => {
    const {
        openParentModal,
        setOpenParentModal,
        openModal,
        targetData,
        isEdit,
        purchaseOrder,
        purchaseOrderItems,
        isImportShipment,
        setOpenModal,
        headerTitle,
        currentPage,
        queryClient,
        setCurrentPage
    } = props;
    const {userInfo} = useSelector(userSelector);
    const [openModalManually, setOpenModalManually] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [items, setItems] = useState([]);
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const rootRef = useRef(null);
    const [error, setErrors] = useState(false);
    const {organizationId} = useSelector(orgSelector);
    const {locationId} = useSelector(locationSelector);

    useEffect(() => {
        
        getItemsByFilter({ page: 0, limit: 5, sortByType: sortByTypeType.DESC, organizationId, isActive: true, locationId })?.then(res => {
            const totalCount = res?.data?.totalEntities;
            if (totalCount === 0) {
                setItemList([]);
            } else {
                const pageBreak = Math.ceil(totalCount / defaultpageCount);
                let listTemps: any = [];
                for (let index = 0; index < pageBreak; index++) {
                    // eslint-disable-next-line
                    getItemsByFilter({ page: index, limit: defaultpageCount, sortByType: sortByTypeType.DESC, organizationId, isActive: true, locationId })?.then(resPage => {
                        if (listTemps.length === 0) {
                            listTemps = resPage?.data?.entities;
                        } else {
                            listTemps = listTemps.concat(resPage?.data?.entities);
                        }
      
                        if (listTemps.length > 0) {
                            const newOption = listTemps;
                            setItemList(newOption);
                        }
                      });
                }
            }
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (itemList?.length > 0) {
            setIsLoading(true);
            const settimeout = setTimeout(() => {
            const itemDatas: any = [];
            for (let index = 0; index < targetData.length; index++) {
                const itemFromFile = targetData[index];
                const itemFromOrder = checkExitsUpc(purchaseOrderItems, "purchaseOrderItems", itemFromFile.upc) //purchaseOrderItems.find((x: any) => x.item?.upcs?.some((upc: any) => upc.upcCode ===itemFromFile.upc));
                if (itemFromOrder) {
                    itemFromOrder.quantityReceived = itemFromOrder.quantityReceived + (itemFromFile.quantity * itemFromOrder.item?.upcs?.find((x: any) => x.upcCode === itemFromFile.upc)?.caseQuantity);
                    itemFromOrder.unitOrdered = itemFromOrder.quantityOrdered * itemFromOrder.item?.upcs?.find((x: any) => x.upcCode === itemFromFile.upc)?.caseQuantity
                    itemDatas.push(itemFromOrder);
                } else {
                    const otherItem: any = checkExitsUpc(itemList, "itemList", itemFromFile.upc) //itemList.find((x: any) => x.item?.upcs?.some((upc: any) => upc.upcCode ===itemFromFile.upc));
                    const poItemNew: any = {
                        itemId: '',
                        item: null,
                        quantityOrdered: 0,
                        quantityReceived: 0,
                        unitOrdered: 0,
                    };
                    if (otherItem) {
                        poItemNew.itemId = otherItem.id;
                        poItemNew.item = otherItem;
                        poItemNew.quantityReceived = itemFromFile.quantity * otherItem?.upcs?.find((x: any) => x.upcCode === itemFromFile.upc)?.caseQuantity;
                        itemDatas.push(poItemNew);
                    } else {
                        poItemNew.errors = t('itemPage.notFoundItem').replace('%UPC%', itemFromFile.upc || '');
                        itemDatas.push(poItemNew);
                    }
                }

                setItems(itemDatas);
                setIsLoading(false);
            }
        }, 1000);
        }
        // eslint-disable-next-line
    }, [purchaseOrderItems, targetData, itemList])

    const checkExitsUpc = (items: any, type: string, upc: string) => {
        for (let index = 0; index < items?.length; index++) {
            if (type === "itemList") {
                const item = items[index];
                for (let index = 0; index < item.upcs?.length; index++) {
                    const itemUpc = item.upcs[index];
                    if (itemUpc?.upcCode === upc) {
                        return item;
                    }
                }
            } else {
                const item = items[index]?.item;
                const poItem = items[index];
                for (let index = 0; index < item.upcs?.length; index++) {
                    const itemUpc = item.upcs[index];
                    if (itemUpc?.upcCode === upc) {
                        return poItem;
                    }
                }
            }
        }
    }

    const toggleHandler = () => {
        setOpenModal(!openModal);
        setOpenParentModal(!openParentModal);
    };

    const toggleContinue = async () => {
        setIsLoading(true);
        purchaseOrder.purchaseOrderItems = items.filter((x: any) => x.itemId && x.itemId !== '');
        const partiallyUnReceive = purchaseOrder.purchaseOrderItems?.filter((x: any) => x.quantityReceived === 0);
        if (partiallyUnReceive && partiallyUnReceive?.length === purchaseOrder.purchaseOrderItems?.length) {
            purchaseOrder.status = 'Ordered'
        } else {
            const partiallyCheck = purchaseOrder.purchaseOrderItems?.filter((x: any) => x.quantityReceived < x.unitOrdered);
            if (partiallyCheck && partiallyCheck?.length > 0) {
                purchaseOrder.status = 'PartiallyReceived';
            } else {
                purchaseOrder.status = 'Received';
            }
        }
        purchaseOrder.receivedBy = userInfo.id;
        purchaseOrder.receivedUser = {id: userInfo.id};
        const res = await updatePurchaseOrderById(purchaseOrder.id, purchaseOrder);
        setData(targetData);
        setIsLoading(false);
        setIsImport(!isImport);
        setOpenModalManually(!openModalManually);
    };

    const handleChangeContentOverview = (id: string, value: any, field: any) => {
        const listContentClone = cloneDeep(items);
        const findContent: any = listContentClone?.find((item: any) => item.itemId === id);
        findContent[field] = parseInt(value);
        setItems(listContentClone);
    };

    return (
        <>
            <div ref={rootRef} className="import-shipment-modal h-modal">
                <Modal
                    show={openModal}
                    size="6xl"
                    className="stock-modal"
                    root={rootRef.current ?? undefined}
                    onClose={toggleHandler}
                    dismissible={false}
                    theme={themModal}
                >
                    <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                    <Modal.Body theme={{base: 'flex flex-col p-[30px] w-full max-h-[700px] overflow-y-auto'}}>
                        {/*Order Item*/}
                        <Table className="mb-[44px]">
                            <Table.Head className="text-gray-900 border-b border-gray-100 border-b-2 w-full">
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs w-[40%] px-3 py-2">
                                    {t('itemPage.item')}
                                </Table.HeadCell>
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs w-[15%] px-3 py-0">
                                    {t('itemPage.sku').toUpperCase()}
                                </Table.HeadCell>
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs w-[15%] px-3 py-0">
                                    {t('orderPage.caseOrdered').toUpperCase()}
                                </Table.HeadCell>
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs w-[15%] px-3 py-0">
                                    {t('orderPage.unitOrdered').toUpperCase()}
                                </Table.HeadCell>
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs w-[15%] px-3 py-0">
                                    {t('orderPage.unitReceived').toUpperCase()}
                                </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className={cn("", {
                                '--overflow': items?.length > 6
                            })}>
                                {items?.map((item: any, index) => {
                                    return (
                                        <Table.Row key={`row-${item.id}`}
                                                   className="bg-white text-sm hover:bg-gray-50 border-b border-gray-100">
                                            <Table.Cell className="px-3 py-3 w-[40%]">
                                                <Tooltip
                                                    content={item.item?.name}>
                                                        <Label>{item?.item?.name}</Label>
                                                        {item.errors && (<div
                                                                            className={`text-red-600 text-xs font-normal mt-1 warning`}>{item.errors}</div>)}
                                                </Tooltip>
                                            </Table.Cell>
                                            <Table.Cell className="px-3 py-3 w-[15%]">
                                                <Label>{item?.item?.sku}</Label>
                                            </Table.Cell>
                                            <Table.Cell className="px-3 py-3 w-[15%]">
                                                <Label>{item?.quantityOrdered}</Label>
                                            </Table.Cell>
                                            <Table.Cell className="px-3 py-3 w-[15%]">
                                                <Label>{item?.unitOrdered}</Label>
                                            </Table.Cell>
                                            <Table.Cell className="px-3 py-3 w-[15%] custom-input">
                                                <TextInput
                                                    type="number"
                                                    name={`quantityReceived-${index}`}
                                                    value={item?.quantityReceived}
                                                    onChange={(e: any) => {
                                                        handleChangeContentOverview(item?.itemId, e.target.value, 'quantityReceived')
                                                    }}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                        {isLoading && <SpinnerComponent/>}
                        <GroupButton
                            className="flex justify-end fixed-group-buttons"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.continue'),
                                    classType: 'blue',
                                    action: () => toggleContinue(),
                                },
                            ]}
                        />
                    </Modal.Body>
                </Modal>
            </div>
            {openModalManually && (
                <ReconcileReviewModal
                    openParentModal={openModal}
                    setOpenParentModal={setOpenModal}
                    openRootModal={openParentModal}
                    setOpenRootModal={setOpenParentModal}
                    openModalReview={openModalManually}
                    setOpenModalReview={setOpenModalManually}
                    headerTitle={isEdit ? t('stockPage.importShipment') : t('stockPage.reconcileStock')}
                    subHeaderTitle={t('stockPage.subHeaderImportModal')}
                    isEdit={true}
                    isImportShipment={isImportShipment}
                    targetData={data}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
        </>
    );
};
export default ReceivedShipmentModal;
