/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import { toast } from 'react-toastify';
import { checkOrgAdmin, checkPermission, getUserOrg, messageErrors } from 'utils/utils';
import useUser from 'hooks/useUser';
import { TextInput, Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { themModal } from 'utils/theme';
import classNames from 'classnames';
import '../../form/styles.scss';
import { pageType, permissionKeys, sortByTypeType, defaultpageCount } from 'utils/constants';
import { createRegion, getRegionByFilter, updateRegionById, getNumberRegionById, getChildrenRegionByParent } from 'api/regionApi';
import { getLocationByFilter } from 'api/locationApi';
import { getListUserOrgs } from 'api/userApi';
import MultiSelect from 'components/select/index'
import { isEmpty } from 'lodash';
import InputText from 'components/commonComponent/inputText';
import makeAnimated from 'react-select/animated';
import { orgSelector, userSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import RegionModalError from './regionModalError';
const animatedComponents = makeAnimated();

type regionModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  headerTitle?: string,
  targetData?: any,
  isEdit: boolean,
  queryClient?: any,
  setCurrentPage?: any,
  currentPage?: number,
  expandedKeys?: any,
  setExpandedKeys: Function
};
const GroupModal = (props: regionModalProps) => {
  const { openModal, targetData, isEdit, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage, expandedKeys, setExpandedKeys } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const {userInfo} = useSelector(userSelector);
  const {WRITE_USER, PLATFORM_ADMIN} = permissionKeys;
  const rootRef = useRef(null);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(null);
  const [regionTypeOptions, setRegionTypeOptions] = useState([{label: '...', value: null}]);
  const [locationTypeOptions, setLocationTypeOptions] = useState([{label: '...', value: null}]);
  const [error, setErrors] = useState(false);
  const { organizationId } = useSelector(orgSelector);
  const [openModalError, setOpenModalError] = useState(false);
  const organization = userInfo.organizations?.find((o: any) => o.id === organizationId);
  const userOrgRoles = organization.roles;
  let isUserAccessManager = false;
  userOrgRoles?.forEach((u: any) => {
    if (u.displayName === 'User Access Manager') isUserAccessManager = true; return;
  });
  const isAdmin = checkPermission(userInfo, pageType.ORGANIZATION, [PLATFORM_ADMIN], organizationId);
  const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);
  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required(t('userManagementPage.requiredField')).max(150, t('locationModal.maxLength150')),
  });

  const hookForm = useForm({
    mode: "onChange",
    resolver: yupResolver(ValidateSchema)
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    reset,
    getValues,
  } = hookForm;

  useEffect(() => {
    const pageSize = 100
    if (isEdit) {
      const targetId = targetData.id;
      getRegionByFilter({page: 0, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId})?.then(res => {
        const totalCount = res?.data?.totalEntities;
        if (totalCount === 0) {
            setRegionTypeOptions([]);
        } else {
            const pageBreak = Math.ceil(totalCount / pageSize);
            let listTemps: any = [];
            for (let index = 0; index < pageBreak; index++) {
                // eslint-disable-next-line
                getRegionByFilter({page: index, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId})?.then(resPage => {
                  if (listTemps.length === 0) {
                    listTemps = resPage?.data?.entities;
                  } else {
                    listTemps = listTemps.concat(resPage?.data?.entities);
                  }
      
                  if (listTemps.length > 0) {
                      const  newOption = listTemps.map((i: any)=> ({label: i.name, value: i.id}));
                      const newOptionFilter = newOption.filter((gr: any) => gr.value !== targetId);
                      getChildrenRegionByParent(targetId)?.then(resChild => {
                        const chilDataOption = resChild?.data?.map((i: any)=> ({label: i.name, value: i.id}));
                        const newOptionFilterData = symmetricDifference(newOptionFilter, chilDataOption);
                        setRegionTypeOptions(newOptionFilterData);
                      });
                  }
                });
            }
        }
      });
    } else {
      getRegionByFilter({page: 0, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId})?.then(res => {
        const totalCount = res?.data?.totalEntities;
        if (totalCount === 0) {
            setRegionTypeOptions([]);
        } else {
            const pageBreak = Math.ceil(totalCount / pageSize);
            let listTemps: any = [];
            for (let index = 0; index < pageBreak; index++) {
                // eslint-disable-next-line
                getRegionByFilter({page: index, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId})?.then(resPage => {
                  if (listTemps.length === 0) {
                    listTemps = resPage?.data?.entities;
                  } else {
                    listTemps = listTemps.concat(resPage?.data?.entities);
                  }
      
                  if (listTemps.length > 0) {
                    const newOption = listTemps.map((i: any) => ({ label: i.name, value: i.id }));
                    setRegionTypeOptions(newOption);
                  }
                });
            }
        }
      });
    }

    getLocationByFilter({ page: 0, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId })?.then(res => {
      const totalCount = res?.data?.totalEntities;
      if (totalCount === 0) {
          setLocationTypeOptions([]);
      } else {
          const pageBreak = Math.ceil(totalCount / pageSize);
          let listTemps: any = [];
          for (let index = 0; index < pageBreak; index++) {
              // eslint-disable-next-line
              getLocationByFilter({ page: index, limit: pageSize, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId })?.then(resPage => {
                  if (listTemps.length === 0) {
                      listTemps = resPage?.data?.entities;
                  } else {
                      listTemps = listTemps.concat(resPage?.data?.entities);
                  }

                  if (listTemps.length > 0) {
                      const  newOption = listTemps.map((i: any)=> ({label: i.name, value: i.id}));
                      setLocationTypeOptions(newOption);
                  }
                });
          }
      }
  });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEdit) {
      const parentRegionId = targetData.parentRegion?.id;
      const selectRegion: any = regionTypeOptions.filter((gr: any) => gr?.value === parentRegionId);
      setSelectedRegion(selectRegion);
      setValue('parentRegion', targetData.parentRegion); 
      setValue('name', targetData.name);
      const locations = targetData.locations?.map((l: any) => {return {label: l.name, value: l}});
      setSelectedLocations(locations);
      setValue('locations', targetData.locations);
      reset(targetData);
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [regionTypeOptions, targetData]);

  const handleAddAndUpdateRegion = async (payload: any) => {
    if (isEdit) {
      payload.organizationId = organizationId;
      payload.organization = {id: organizationId};
      payload.locations.map((location: any) => { location.organizationId ? location.organization = {id: location.organizationId} : location.organization = null; return location });
      if (payload.parentRegion) {
        const response = await getNumberRegionById(payload.parentRegion);
        const numberRegion = response.data;
        if (numberRegion !== 10) {
          await updateRegionById(targetData.id, payload);
          toggleHandler();
          const message: string = t('regionPage.editSuccess');
          toast.success(message);
          fetchCurrentUser();
        }
        else {
          if (payload.id !== targetData.id) {
            setOpenModalError(true);
          } else {
            await updateRegionById(targetData.id, payload);
            toggleHandler();
            const message: string = t('regionPage.editSuccess');
            toast.success(message);
            fetchCurrentUser();
          }
        }
      } else {
        await updateRegionById(targetData.id, payload);
        toggleHandler();
        const message: string = t('regionPage.editSuccess');
        toast.success(message);
        fetchCurrentUser();
      }
    } else {
      payload.organizationId = organizationId;
      payload.organization = {id: organizationId};
      if (payload.parentRegion) {
        const response = await getNumberRegionById(payload.parentRegion);
        const numberRegion = response.data;
        if (numberRegion !== 10) {
          await createRegion(payload);
          toggleHandler();
          const message: string = t('regionPage.saveSuccess');
          toast.success(message);
          fetchCurrentUser();
        }
        else {
          setOpenModalError(true);
        }
      } else {
        await createRegion(payload);
        toggleHandler();
        const message: string = t('regionPage.saveSuccess');
        toast.success(message);
        fetchCurrentUser();
      }
    }
  };

  const mutation = useMutation(['create-update-region'], { mutationFn: handleAddAndUpdateRegion });

  const onSubmit = (values: any, props: any) => {
    mutation.mutate(values, {
      onSuccess: async () => {
        if (currentPage !== 0) {
          queryClient.invalidateQueries('getLocations', { refetchActive: false }, { cancelRefetch: true });
          setCurrentPage(0);
        } else queryClient.invalidateQueries('getLocations', { refetchActive: true }, { cancelRefetch: true });
         setExpandedKeys(expandedKeys);
      },
      onError: error => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      },
    });
  };

  const handleChangeRegion = (option: any) => {
    setSelectedRegion(option || []);
    setValue('parentRegion', option ? option.value : null);
  };

  const handleChangeLocation = (options: any) => {
    setSelectedLocations(options);
    const locations: any = [];
    options?.forEach((op: any) => {locations.push(op.value)});
    setValue('locations', locations.map((lo: any) => {return {id: lo.id, organizationId: organizationId, organization: {id: organizationId}}}));
    setErrors(isEmpty(options));
  };

  const toggleHandler = () => {
    setOpenModal(!openModal);
    reset();
  };

  const symmetricDifference = (arr1: any, arr2: any) => {
    const set1 = new Set(arr1.map((item: any) => item.value));
    const set2 = new Set(arr2.map((item: any) => item.value));
    const diff = new Set([...arr1.filter((x: any )=> !set2.has(x.value)), ...arr2.filter((x: any) => !set1.has(x.value))]);
    return Array.from(diff);
}

  return (
    <>
    <div ref={rootRef} className="group-modal-container">
      <Modal
        show={openModal}
        size="xl"
        className="group-modal"
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        // dismissible={true}
        theme={themModal}
        // popup
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body theme={{ base: 'flex-12 pb-4 pt-3 px-12' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-4 relative">
            <InputText 
              hookForm={hookForm}
              placeholder={`${t('regionPage.modal.modalRegionName')}`}
              name="name"
              id="name"
              isHolderLabel={true}
            />
            </div>
            <div className="mt-4 relative">
              <MultiSelect
                    onChange={(option: any) => handleChangeRegion(option)}
                    options={regionTypeOptions}
                    value={selectedRegion}
                    hookForm={hookForm}
                    name="parentRegion"
                    className={classNames('react-select', { error })}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => t('No options')}
                    isHolderLabel={true}
                    placeholder={`${t('regionPage.modal.modalParentRegion')}`}
                  />
              </div>
              <div className="mt-4 relative">
              <MultiSelect
                id="locations"
                name="locations"
                hookForm={hookForm}
                isMulti={true}
                options={locationTypeOptions}
                components={animatedComponents}
                onChange={(option: any) => handleChangeLocation(option)}
                className={classNames('react-select', { error })}
                classNamePrefix="react-select"
                noOptionsMessage={() => t('No options')}
                placeholder={`${t('regionPage.modal.modalLocations')}`}
                value={selectedLocations}
                isHolderLabel={true}
              />
              </div>
            <GroupButton
              className="items-center justify-center pt-5 mb-4"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => toggleHandler(),
                },
                {
                  type: 'submit',
                  text: t('modal.save'),
                  classType: 'blue',
                  isLoading: mutation.isLoading,
                },
              ]}
            />
          </form>
        </Modal.Body>
      </Modal>
    </div>
     <RegionModalError
          headerTitle={isEdit ? '' : ''}
          openModalError={openModalError}
          isEdit={isEdit}
          targetData={null}
          setOpenModalError={setOpenModalError}
          queryClient={queryClient}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
      />

    </>
  );
};
export default GroupModal;
