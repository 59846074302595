/* eslint-disable react/jsx-props-no-spreading */
import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import CustomModalHeader from './customModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { userAction } from 'redux/actions';
import { useTranslation } from 'react-i18next';
import 'styles/styles.scss';
import { locationSelector, orgSelector, userSelector } from 'redux/selectors';
import { getLocationByFilter } from 'api/locationApi';
import { pageType, permissionKeys, sortByTypeType, defaultpageCount } from 'utils/constants';
import { checkOrgAdmin, checkPermission, getUserOrg } from 'utils/utils';
import MultiSelect from 'components/select/index';
import makeAnimated from 'react-select/animated';
import { generateQrCode } from 'api/userApi';
import GroupButton from 'components/button/groupButton';
import './QRCodeModal.scss'
const animatedComponents = makeAnimated();

const ScannerModal = () => {
    const rootRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const [t] = useTranslation()
    const {userInfo} = useSelector(userSelector);
    const {WRITE_USER, PLATFORM_ADMIN} = permissionKeys;
    const { isOpenScannerModal } = useSelector(userSelector);
    const { organizationId } = useSelector(orgSelector);
    const { locationId } = useSelector(locationSelector);
    const [locationTypeOptions, setLocationTypeOptions] = useState([{label: '...', value: null}]);
    const [selectedLocations, setSelectedLocations] = useState(null);
    const isAdmin = checkPermission(userInfo, pageType.ORGANIZATION, [PLATFORM_ADMIN], organizationId);
    const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);
    const [urlQrCode, setUrlQrCode] = useState('');

    useEffect(() => {
        getLocationByFilter({ page: 0, limit: 5, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId })?.then(res => {
            const totalCount = res?.data?.totalEntities;
            if (totalCount === 0) {
                setLocationTypeOptions([]);
            } else {
                const pageBreak = Math.ceil(totalCount / defaultpageCount);
                let listTemps: any = [];
                for (let index = 0; index < pageBreak; index++) {
                    // eslint-disable-next-line
                    getLocationByFilter({ page: index, limit: defaultpageCount, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId })?.then(resPage => {
                        if (listTemps.length === 0) {
                            listTemps = resPage?.data?.entities;
                        } else {
                            listTemps = listTemps.concat(resPage?.data?.entities);
                        }

                        if (listTemps.length > 0) {
                            const  newOption = listTemps.map((i: any)=> ({label: i.name, value: i.id}));
                            setLocationTypeOptions(newOption);
                        }
                      });
                }
            }
        });
        // eslint-disable-next-line
    }, [isOpenScannerModal])

    useEffect(() => {
        if (locationId) {
            const locationDefault: any = locationTypeOptions?.find((x: any) => x.value === locationId);
            setSelectedLocations(locationDefault);
            const fetchQRCode = async () => {
                try {
                    const response = await generateQrCode(organizationId, locationId);
                    const qrCodeImageUrl = URL.createObjectURL(response?.data);
                    setUrlQrCode(qrCodeImageUrl);
                } catch (error) {
                    console.error('Error fetching QR code:', error);
                }
            };

            fetchQRCode();
        }
        // eslint-disable-next-line
    }, [locationTypeOptions])

    const closeModal = () => {
        const isOpenModal: any = false
        dispatch(userAction.setOpenScannerModal(isOpenModal));
    };

    const handleChangeLocation = async (option: any) => {
        if (option) {
            const response = await generateQrCode(organizationId, option.value);
            const qrCodeImageUrl = URL.createObjectURL(response?.data);
            setUrlQrCode(qrCodeImageUrl);
        }
        setSelectedLocations(option);
      };

    return (
        <div ref={rootRef}>
            <Modal
                show={isOpenScannerModal}
                size="lg"
                root={rootRef.current ?? undefined}
                onClose={closeModal}
                dismissible={true}
                className="modal-profile"
            >
                <CustomModalHeader title={t('navbar.scannerLogin')}
                    toggle={closeModal}
                />
                <Modal.Body>
                    <div className="w-full px-[20px] flex-col flex items-center">
                        <div className="mt-4 w-full">
                        <MultiSelect
                            id="locations"
                            name="locations"
                            isMulti={false}
                            options={locationTypeOptions}
                            components={animatedComponents}
                            onChange={(option: any) => handleChangeLocation(option)}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => t('No options')}
                            placeholder={`${t('regionPage.modal.modalLocations')}`}
                            value={selectedLocations}
                            isHolderLabel={true}
                        />
                        </div>
                        {urlQrCode && (
                            <>
                                <div className="QRcode">
                                    <img className="preview h-25 object-cover rounded-lg" src={urlQrCode} alt="QR Code Login" />
                                    <span>Scan Now</span>
                                </div>
                            </>
                        )}
                        <GroupButton
                        className="items-center justify-center pt-5 mb-4"
                        buttons={[
                            {
                            type: 'button',
                            text: t('modal.cancel'),
                            classType: 'white',
                            action: () => closeModal(),
                            },
                        ]}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>)
}
export default ScannerModal